.top-header {
  background: url("./../assests/top-header-bg.jpg");
  background-size: cover;
  padding: 15px 0px 15px 0px;
  /* position: sticky; */
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
}

.header {
  box-shadow: 0px 2px 5px 0px rgb(12 24 72 / 6%);
  padding: 8px 0px 8px 0px;
}

.header-input {
  border: 1px solid #cedcf9;
  font-size: 14px;
  outline: none;
  border-radius: 8px;
  padding: 7px;
  background-image: url();
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: 10px;
  color: #666;
  width: 100%;
}
