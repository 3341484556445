
.bot-container{
    -moz-box-shadow:    1px 1px 1px 2px #ccc;
    -webkit-box-shadow: 1px 1px 1px 2px #ccc;
    box-shadow:         1px 1px 1px 2px #ccc;
    background-color: #fff;
    border-radius: 12px;
    width: 100%;
    padding:20px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
}
.bot_row1{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex: 1 2 1;
}
.bot_row1_col1 {
    width: 35%;
}
.bot_row1_col2 {
    width: 60%;
    margin: 0px 10px;
}
.bot_row1_col3 {
    width: 18%;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
}
.m5{
    margin:5px;
}
.mr-1 {
    margin-right: 10px;
}
.w-100{
    width: 100%;
}
.w-90 {
    width: 90%;
}
.w-80 {
    width: 80%;
}
.holder_br{
    overflow: hidden;
}
.bot_input_holder{
    width: 100%;
    display: flex;
    flex-direction: column;
    
}
.bot_input {
    background-color: #c4c4c4;
    border-radius: 12px;
    border: 1px solid #c4c4c4;
    font-size: 1rem;
    flex:1;
    padding: 5px 10px;
}
.bot_input:focus{
    outline: none;
    border: 2px solid rgb(92, 110, 212);
}
.bot_input_label{
    letter-spacing: 0;
    min-height: 17px;
    min-width: 123px;
    font-weight: 600;
    font-size: 12px;
    color: #000000 !important;
    margin: 0;
    justify-content: center;
    align-items: center;
}
.bot-select {
    background-color: #c4c4c4;
    border-radius: 12px;
    display: flex;
    cursor: pointer;
    color:#000000;
    padding: 0 5px;
}
  
.bot-select-container {
    position: absolute;
    z-index: 100;
    background-color: #7f7c8f;
    border-end-start-radius: 10px;
    border-end-end-radius: 10px;
    left: 0;
    top: 25px;
}
.bot-select-item{
    cursor: pointer;
    padding: 10px;
}
.bot-select-item:hover{
    background-color: aqua;
}
.bot_btn{
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    display: flex;
    cursor: pointer;
}
.danger {
    background-color: red;
}
.success{
    background-color: #38a538;
}
.bot_btn:hover{
    background-color: aqua;
}
.bot_btn_txt{
    font-weight: 600;
    font-size: 1rem;
    color: #000000 !important;
}
.bot_shell{
    background-color: black;
    height: 150px;
    width: 100%;
    padding: 10px;
    border-radius: 12px;
}
.shell_txt{
    font-size: 0.8rem;
    width: 90%;
    word-wrap: break-word;
}
.bot_check_box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.alignFirst{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.align{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.bot_input_check::-webkit-outer-spin-button,
.bot_input_check::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.bot_input_check[type=number] {
  -moz-appearance: textfield;
}
.bot_input_check {
    background-color: transparent;
    font-size: 1rem;
    word-wrap: break-word;
    border: none;
    margin-left: 2px;
    padding: 0px;
}
.bot_input_check:focus{
    outline: none;
}
.wrap {
    display: flex;
    flex-wrap: wrap;
}
.percentage{
    background-color: #c4c4c4;
    border-radius: 12px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    color:#000000 !important;
    margin: 2px;
    font-size: 12px;
}
.percentage:hover{
    background-color: aqua;
}
.percentage_active {
    background-color:#38a538;
}
.m-0{
    margin: 0;
}
.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
    width: 9px;
}

.scrollable::-webkit-scrollbar-track {
    background-color: #c4c4c43b; 
    border-radius: 12px;
}

.scrollable::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #ffffffc2; 
    height: 35px;
}
@media only screen and (min-width: 1200px) {
    .bot_conainer{
        width: 1000px;
    }
}
@media only screen and (max-width: 720px) {
    .bot_input_label{
        font-size: 14px;
    }
    .bot_input_holder_phone{
        display: block;
    }
    .percentage{
        font-size: 14px !important;
    }
    .bot_conainer{
        width: 92%;
    }
    .bot_row1{
        display: block;
    }
    .w-80 {
        width: 100%;
    }
    .bot_row1_col1{
        width: 100%;
    }
    .bot_row1_col2{
        width: 100%;
        margin: 0;
    }
    .bot_row1_col3{
        width: 100%;
    }
    .bot_input_holder_phone {
        width: 30%;
    }
}