@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assests/font/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./assests/font/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("./assests/font/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./assests/font/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assests/font/Inter-Bold-1.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./assests/font/Inter-Black.ttf") format("truetype");
}

body {
  font-family: "Inter";
  /* font-family: 'Roboto', sans-serif; */
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.m-bold {
  font-weight: bold;
}

.shadow-a-1 {
  box-shadow: 0px 30px 50px 0px rgb(70 89 119 / 25%);
}
.shadow-a-2 {
  box-shadow: 0px 20px 40px 0px rgb(2 30 70 / 10%);
}
.shadow-a-3 {
  box-shadow: 0px 0px 30px 0px rgb(0 85 156 / 11%);
}
.shadow-a-4 {
  box-shadow: 0px 6px 12px 0px rgb(0 20 70 / 14%);
}
.shadow-a-5 {
  box-shadow: 0px 5px 10px 0px rgb(3 41 91 / 7%);
}
.shadow-a-6 {
  box-shadow: 0px 3px 9px 0px #e6edf3;
}
.shadow-a-7 {
  box-shadow: 0px 2px 5px 0px rgb(12 24 72 / 6%);
}
.shadow-a-8 {
  box-shadow: 0 1px 0 #fff, 0 -2px 5px rgb(0 0 0 / 8%) inset;
}
.shadow-a-9 {
  box-shadow: 0 0 15px rgb(81 71 217 / 15%);
}
.shadow-a-10 {
  box-shadow: 1px 2px 6px #e8e4e4;
}
.shadow-a-11 {
  box-shadow: 0px 10px 20px 0px
    rgba(0, 3.999999999999985, 20.999999999999996, 0.07);
}

.shadow-a-12 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-a-13 {
  box-shadow: 0px 2px 6px #ced4da;
}

.anam-play div {
  width: 100%;
  height: 100%;
}

.anam-play iframe {
  width: 100% !important;
  height: 100% !important;
}

.contact-card:hover {
  transition: all 0.3s;
  box-shadow: 0px 20px 40px 0px rgb(2 30 70 / 10%);
}

.btn-1 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 21px 24px 20px 24px;
  display: inline-flex;
  gap: 10px;
  transition: all 0.3s;
}
.btn-1:hover {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}
.btn-1 img {
  width: 18px;
}

.trustedby-card {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9e9e9;
  background-color: #fff;
  margin: 0px 10px 0px 0px;
  padding: 20px 26px 15px 26px;
  border-radius: 8px 8px 8px 8px;
}

.l-s {
  letter-spacing: 0.3px;
}

.play-ani {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url("./assests/play-button.webp");
  background-color: #007bff;
  cursor: pointer;
  /* animation: shadow-pulse 1s infinite cubic-bezier(.57,.58,.45,.46);
  -webkit-animation: shadow-pulse 1s infinite cubic-bezier(.57,.58,.45,.46); */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-color: white;
}

.automotion-slider .swiper {
  padding-bottom: 50px !important;
}

.AutomatingAnything {
  padding: 100px 0px 120px 0px;
  background-color: #053099;
  position: relative;
}
.AutomatingAnything-overlay {
  background-image: url("./assests/Automating\ Anything-bg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.06;
}

.FeaturesYouNeed-row {
  border-color: #efefef;
}
.FeaturesYouNeed-row:hover {
  box-shadow: 0px 15px 30px 0px rgb(0 3 16 / 12%);
  transition: all 0.3s;
}
.FeaturesYouNeed-row:nth-child(1),
.FeaturesYouNeed-row:nth-child(3) {
  border-width: 0px 2px 2px 0px;
}
.FeaturesYouNeed-row:nth-child(2),
.FeaturesYouNeed-row:nth-child(4) {
  border-width: 0px 0px 2px 0px;
}

.FeaturesYouNeed-row:nth-child(5) {
  border-width: 0px 2px 0px 0px;
}

.Connect1000Applications-bg {
  background: url("./assests/Connect-1000-Applications-1.webp");
  /* background-size: 100%; */
  animation: moves 25s infinite linear;
  height: 496px;
}

.NewIntegrationsLaunched-card {
  transition: all 0.3s;
}

.NewIntegrationsLaunched-card:hover {
  box-shadow: 0px 12px 25px 0px rgb(0 20 70 / 19%);
}
.master-img {
  box-shadow: 0 10px 30px 0 rgb(0 11 47 / 12%);
}
.master-wrapper:hover .master-img {
  box-shadow: 0 30px 40px 0 rgb(1 22 52 / 25%);
  transition: all 0.3s;
}

.pricing-filter:first-child {
  border-radius: 50px 0 0 50px;
}
.pricing-filter:last-child {
  border-radius: 0 50px 50px 0;
}

.price-li span {
  color: #030025;
  font-weight: 700;
}

.totalFreeCost-card:not(.totalFreeCost-card:first-child) {
  border-bottom: 1px solid #e2e8ef;
}
.totalFreeCost-card:first-child {
  border-radius: 10px 10px 0 0;
}
.pricing-card {
  box-shadow: 0px 6px 12px 1px rgb(1 38 125 / 8%);
}

.ques-bg {
  background-image: linear-gradient(180deg, #ffffff 40%, #f6fcff 100%);
}

.google-or-text {
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 15px;
}
.google-or-text::before {
  position: absolute;
  max-width: 46%;
  height: 1px;
  top: 14px;
  background-color: #ccc;
  content: "";
  width: 100%;
  left: 0;
}
.google-or-text::after {
  position: absolute;
  max-width: 46%;
  height: 1px;
  top: 14px;
  background-color: #ccc;
  content: "";
  width: 100%;
  right: 0;
}
.google-or-text span {
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 3px 5px;
  font-size: 14px;
}

/* about us */
.about-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assests/about/about.jpg");
  opacity: 0.25;
  background-attachment: fixed;
  background-position: bottom left;
  background-repeat: repeat;
  opacity: 0.25;
  mix-blend-mode: luminosity;
}

/* affiliate */
.affiliate-card:hover {
  transition: all 0.3s;
  box-shadow: 0px 20px 40px 0px
    rgba(0, 3.999999999999985, 20.999999999999996, 0.12);
}

.TermsCondition h3 {
  padding-top: 25px;
  padding-bottom: 15px;
}

/* menu */

.hover-pro-wrapper {
  display: none;
}
.pro-hove-btn:hover .hover-pro-wrapper {
  display: block;
}

.shak-btn {
  animation: shakehv 5s infinite ease-in-out;
  display: inline-block;
}
.shadowPlushAnimation {
  animation: shadow-pulse 1s infinite cubic-bezier(0.57, 0.58, 0.45, 0.46);
}

button:disabled,
button[disabled] {
  background-color: #dee1e6;
  cursor: not-allowed;
}

.filter-active-btn {
  background-color: #007bff !important;
  color: #fff717 !important;
}
.anam-play div {
  width: 100%;
  height: 100%;
}

.anam-play iframe {
  width: 100% !important;
  height: 100% !important;
}

.my-terminal {
  min-height: 300px;
  height: 300px;
}
.my-terminal > div {
  min-height: 300px;
  height: 300px;
}
.dMFuoo::-webkit-scrollbar {
  width: 0px !important;
}
.terminal-base {
  border-radius: 10px;
  overflow: hidden;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(25, 74, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px transparent;
  }
}

@keyframes moves {
  100% {
    background-position: -100vw 80%;
  }
}

@keyframes shakehv {
  0%,
  80% {
    transform: translate(0, 0) rotate(0);
  }
  60%,
  70% {
    transform: translate(0, -0.5px) rotate(2.5deg);
  }
  62%,
  72% {
    transform: translate(0, 1.5px) rotate(-0.5deg);
  }
  65%,
  75% {
    transform: translate(0, -1.5px) rotate(2.5deg);
  }
  67%,
  77% {
    transform: translate(0, 2.5px) rotate(-1.5deg);
  }
}

@media only screen and (max-width: 768px) {
  .pricing-filter:last-child {
    border-radius: 50px;
  }
  .pricing-filter:first-child {
    border-radius: 50px;
  }
  .FeaturesYouNeed-row {
    border: none !important;
  }
}
