.elementor-kit-26487 {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-1995b58e: #4054b2;
  --e-global-color-1092daf1: #23a455;
  --e-global-color-1877ff42: #000;
  --e-global-color-42746450: #fff;
  --e-global-typography-primary-font-family: "Inter";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Inter";
  --e-global-typography-secondary-font-weight: 800;
  --e-global-typography-text-font-family: "Inter";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Inter";
  --e-global-typography-accent-font-weight: 500;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}

.e-con {
  --container-max-width: 1140px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-element {
  --widgets-spacing: 20px;
}

h1.entry-title {
  display: var(--page-title-display);
}

.elementor-kit-26487 e-page-transition {
  background-color: #ffbc7d;
}

@media (max-width: 1024px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }
  .e-con {
    --container-max-width: 1024px;
  }
}

@media (max-width: 767px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 767px;
  }
  .e-con {
    --container-max-width: 767px;
  }
}

/* Start Custom Fonts CSS */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/public/fonts/Inter-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/public/fonts/Inter-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/public/fonts/Inter-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("/public/fonts/Inter-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/public/fonts/Inter-Bold-1.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("/public/fonts/Inter-Black.ttf")
  format("truetype");
}

/* End Custom Fonts CSS */

.elementor-widget-heading .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-image .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-text-editor {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap,
.elementor-widget-text-editor.elementor-drop-cap-view-default
  .elementor-drop-cap {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}

.elementor-widget-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-divider {
  --divider-color: var(--e-global-color-secondary);
}

.elementor-widget-divider .elementor-divider__text {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-divider.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-secondary);
}

.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon {
  color: var(--e-global-color-secondary);
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon svg {
  fill: var(--e-global-color-secondary);
}

.elementor-widget-image-box .elementor-image-box-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-image-box .elementor-image-box-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-icon.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}

.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon svg {
  fill: var(--e-global-color-primary);
}

.elementor-widget-icon-box.elementor-view-stacked .elementor-icon {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-icon-box.elementor-view-framed .elementor-icon,
.elementor-widget-icon-box.elementor-view-default .elementor-icon {
  fill: var(--e-global-color-primary);
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}

.elementor-widget-icon-box .elementor-icon-box-title {
  color: var(--e-global-color-primary);
}

.elementor-widget-icon-box .elementor-icon-box-title,
.elementor-widget-icon-box .elementor-icon-box-title a {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-icon-box .elementor-icon-box-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-star-rating .elementor-star-rating__title {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-image-gallery .gallery-item .gallery-caption {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-icon-list .elementor-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}

.elementor-widget-icon-list .elementor-icon-list-icon i {
  color: var(--e-global-color-primary);
}

.elementor-widget-icon-list .elementor-icon-list-icon svg {
  fill: var(--e-global-color-primary);
}

.elementor-widget-icon-list .elementor-icon-list-text {
  color: var(--e-global-color-secondary);
}

.elementor-widget-icon-list
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-widget-icon-list .elementor-icon-list-item > a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-counter .elementor-counter-number-wrapper {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-counter .elementor-counter-title {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-progress .elementor-progress-wrapper .elementor-progress-bar {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-progress .elementor-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-testimonial .elementor-testimonial-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-testimonial .elementor-testimonial-name {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-testimonial .elementor-testimonial-job {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-tabs .elementor-tab-title,
.elementor-widget-tabs .elementor-tab-title a {
  color: var(--e-global-color-primary);
}

.elementor-widget-tabs .elementor-tab-title.elementor-active,
.elementor-widget-tabs .elementor-tab-title.elementor-active a {
  color: var(--e-global-color-accent);
}

.elementor-widget-tabs .elementor-tab-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-tabs .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-accordion .elementor-accordion-icon,
.elementor-widget-accordion .elementor-accordion-title {
  color: var(--e-global-color-primary);
}

.elementor-widget-accordion .elementor-accordion-icon svg {
  fill: var(--e-global-color-primary);
}

.elementor-widget-accordion .elementor-active .elementor-accordion-icon,
.elementor-widget-accordion .elementor-active .elementor-accordion-title {
  color: var(--e-global-color-accent);
}

.elementor-widget-accordion .elementor-active .elementor-accordion-icon svg {
  fill: var(--e-global-color-accent);
}

.elementor-widget-accordion .elementor-accordion-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-accordion .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-toggle .elementor-toggle-title,
.elementor-widget-toggle .elementor-toggle-icon {
  color: var(--e-global-color-primary);
}

.elementor-widget-toggle .elementor-toggle-icon svg {
  fill: var(--e-global-color-primary);
}

.elementor-widget-toggle .elementor-tab-title.elementor-active a,
.elementor-widget-toggle
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: var(--e-global-color-accent);
}

.elementor-widget-toggle .elementor-toggle-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-toggle .elementor-tab-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-alert .elementor-alert-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-alert .elementor-alert-description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-author .ae-author-meta-wrapper a {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper icon-wrapper {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper a:hover {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper:hover {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper:hover icon-wrapper {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-author .ae-author-meta-wrapper {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-custom-field .ae-element-custom-field {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-custom-field .icon-wrapper i {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-custom-field .ae-element-custom-field-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-portfolio .infinite-scroll-last {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-portfolio .view-more-button {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-portfolio .view-more-button:hover {
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-portfolio .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-portfolio .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-portfolio .filter-items {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-ae-portfolio .filter-label {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-portfolio .filter-items a {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-portfolio .filter-items:hover a,
.elementor-widget-ae-portfolio .filter-items.active a {
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-portfolio .filter-items:hover,
.elementor-widget-ae-portfolio .filter-items.active {
  background-color: var(--e-global-color-primary);
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-post-blocks .infinite-scroll-last {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-blocks .view-more-button {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-blocks .view-more-button:hover {
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-post-blocks .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-post-blocks .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-post-blocks-adv .ae-accordion .ae-tab-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-post-blocks-adv .ae-accordion .ae-tab-title.ae-active {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-blocks-adv
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-post-blocks-adv
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button:hover {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-blocks-adv
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button.active {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-blocks-adv .infinite-scroll-last {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-blocks-adv .view-more-button {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-blocks-adv .view-more-button:hover {
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-post-blocks-adv .filter-label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-post-blocks-adv .filter-items {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-ae-post-blocks-adv .filter-items a {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-blocks-adv .filter-items:hover > a,
.elementor-widget-ae-post-blocks-adv .filter-items > a .filter-items:hover > a,
.elementor-widget-ae-post-blocks-adv .filter-items.active > a {
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-post-blocks-adv .filter-items:hover,
.elementor-widget-ae-post-blocks-adv .filter-items.active {
  background-color: var(--e-global-color-primary);
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-post-blocks-adv .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-post-blocks-adv .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-post-comments .submit {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-post-content .ae-element-post-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-post-content .ae-post-content-unfold {
  background-image: var(--e-global-color-text);
}

.elementor-widget-ae-post-content .ae-post-content-unfold-link {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-content .ae-post-content-unfold-link:hover {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-title .ae-element-post-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-title .ae-element-post-title:hover {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-meta .ae-post-meta-item-wrapper {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-post-meta-item-wrapper a {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-category {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-tags {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-date {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-author {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-comment {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-meta .icon-wrapper i {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-meta .item-separator {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-meta .ae-element-post-category-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-meta .ae-element-post-tags-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-meta .ae-element-post-date-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-meta .ae-element-post-author-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-meta .ae-element-post-comment-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-navigation .ae-element-item-separator {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-navigation .ae-element-anchorPrevLink,
.elementor-widget-ae-post-navigation .ae-element-anchorNextLink {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-navigation .ae-element-prev-icon.icon-wrapper i,
.elementor-widget-ae-post-navigation .ae-element-next-icon.icon-wrapper i {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-navigation .ae-element-prev-title,
.elementor-widget-ae-post-navigation .ae-element-next-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-post-readmore .ae-element-post-read-more a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-readmore .ae-element-icon.icon-wrapper i {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-post-readmore .ae-element-post-read-more a:hover {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-post-readmore a:hover .ae-element-icon.icon-wrapper i {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-searchform button {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  background-color: var(--e-global-color-primary);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-searchform button:hover {
  background-color: var(--e-global-color-primary);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-searchform input {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  background-color: var(--e-global-color-secondary);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-searchform input:hover {
  background-color: var(--e-global-color-primary);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-tax-custom-field .ae-element-custom-field {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-tax-custom-field .icon-wrapper i {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-tax-custom-field .ae-element-custom-field-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-taxonomy .ae-custom-tax a {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-taxonomy .ae-custom-tax .ae-term-item {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-taxonomy .ae-custom-tax * {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-taxonomy .ae-custom-tax-label {
  color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-taxonomy .icon-wrapper i {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-taxonomy-blocks .ae-element-term-title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-taxonomy-blocks
  .ae-term-list-item:hover
  .ae-element-term-title {
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-taxonomy-blocks .ae-term-active .ae-element-term-title {
  color: var(--e-global-color-secondary);
}

.elementor-widget-ae-taxonomy-blocks .ae-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-taxonomy-blocks .term-list-icon i {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-taxonomy-blocks .ae-term-button {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-ae-taxonomy-blocks .swiper-pagination-fraction {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-trigger .ae-trigger {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-ae-dynamic-map .ae-responsive-btn .button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-ae-dynamic-map .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-dynamic-map .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-acf .ae-acf-content-wrapper,
.elementor-widget-ae-acf .ae-acf-content-wrapper a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-acf-content-wrapper:hover,
.elementor-widget-ae-acf .ae-acf-content-wrapper:hover a {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-acf-content-wrapper:hover {
  border-color: var(--e-global-color-text);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-fallback-wrapper .ae-fallback {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-fallback-wrapper:hover .ae-fallback {
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-fallback-wrapper:hover {
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-acf-unfold {
  background-image: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-acf-wrapper .ae-acf-unfold {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-acf .ae-acf-unfold-link {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf .ae-acf-unfold-link:hover {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf .ae-acf-content-wrapper {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf a:hover {
  color: var(--e-global-color-text);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .date {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .date:hover {
  color: var(--e-global-color-text);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-acf .ae-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-acf span {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-text);
}

.elementor-widget-ae-acf span:hover {
  color: var(--e-global-color-text);
  border-color: var(--e-global-color-text);
}

.elementor-widget-ae-acf-gallery .ae-overlay-caption {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-gallery.ae-icon-view-stacked .ae-overlay-icon {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-gallery.ae-icon-view-framed .ae-overlay-icon,
.elementor-widget-ae-acf-gallery.ae-icon-view-default .ae-overlay-icon {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-gallery.ae-icon-view-stacked:hover
  .ae-overlay-icon:hover {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-gallery.ae-icon-view-framed:hover
  .ae-overlay-icon:hover,
.elementor-widget-ae-acf-gallery.ae-icon-view-default .ae-overlay-icon {
  color: var(--e-global-color-primary);
  border-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-gallery .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-acf-gallery .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-title {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-title.active,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-title:hover {
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-mobile-title,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-desktop-title.active,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-title:before,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-title:after,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tab-content,
.elementor-widget-ae-acf-repeater .ae-acf-repeater-tabs-content-wrapper {
  border-color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-repeater .elementor-accordion .elementor-tab-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
  color: var(--e-global-color-primary);
}

.elementor-widget-ae-acf-repeater
  .elementor-accordion
  .elementor-tab-title.ae-active {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-acf-repeater
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ae-acf-repeater
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button:hover {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-acf-repeater
  .ae-accordion-tb-wrapper
  .ae-accordion-toggle-button.active {
  color: var(--e-global-color-accent);
}

.elementor-widget-ae-acf-repeater .ae-widget-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-ae-acf-repeater .ae-widget-title-inner {
  border-color: var(--e-global-color-secondary);
}

.elementor-widget-ucaddon_dropdown_pricing .new_plans_list {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing #curr_rate_m {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing .curr_duration {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing .btn_anchor {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing
  .uc_style_dropdown_pricing_elementor_head_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing
  .uc_style_dropdown_pricing_elementor_subhead_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing
  .uc_style_dropdown_pricing_elementor_uc_items_attribute_strikethrough_amount {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing
  .uc_style_dropdown_pricing_elementor_uc_items_attribute_inr_pricing_content {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_vertical_tab_content
  .eael-advance-tabs
  .eael-tabs-nav
  > ul
  li
  span.eael-tab-title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_vertical_tab_content
  .eael-advance-tabs
  .eael-tabs-nav
  > ul
  li
  span.eael-tab-title
  span.tab_subtitle {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_pabbly_pricing_tabs
  .uc_style_pabbly_pricing_tabs_elementor_uc_items_attribute_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_pabbly_pricing_tabs
  .uc_style_pabbly_pricing_tabs_elementor_uc_items_attribute_tab_content {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_content_switcher
  .uc_style_content_switcher_elementor_heading_1 {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_content_switcher
  .uc_style_content_switcher_elementor_heading_2 {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing .new_dp_plans_list {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing #curr_rate_m {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing .curr_duration {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing .new_dp_btn_anchor {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing
  .uc_style_new_dropdown_pricing_elementor_head_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_new_dropdown_pricing
  .uc_style_new_dropdown_pricing_elementor_uc_items_attribute_data_to_subs_bill {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing .new_plans_list {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing #curr_rate_m {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing .curr_duration {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing .btn_anchor {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing
  .uc_style_pabbly_plus_new_pricing_elementor_head_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing
  .uc_style_pabbly_plus_new_pricing_elementor_subhead_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_Pabbly_Plus_New_Pricing
  .uc_style_pabbly_plus_new_pricing_elementor_uc_items_attribute_strikethrough_amount {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy .new_plans_list {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy #curr_rate_m {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy .curr_duration {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy .btn_anchor {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy
  .uc_style_dropdown_pricing_copy_elementor_head_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy
  .uc_style_dropdown_pricing_copy_elementor_subhead_title {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy
  .uc_style_dropdown_pricing_copy_elementor_uc_items_attribute_strikethrough_amount {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-ucaddon_dropdown_pricing_copy
  .uc_style_dropdown_pricing_copy_elementor_uc_items_attribute_inr_pricing_content {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-text-path {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-theme-site-logo .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-theme-site-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-page-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-post-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-post-excerpt .elementor-widget-container {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-theme-post-content {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-theme-post-featured-image .widget-image-caption {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-theme-archive-title .elementor-heading-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__title,
.elementor-widget-archive-posts .elementor-post__title a {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__meta-data {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__excerpt p {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-archive-posts .elementor-post__read-more {
  color: var(--e-global-color-accent);
}

.elementor-widget-archive-posts a.elementor-post__read-more {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-archive-posts .elementor-post__card .elementor-post__badge {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-archive-posts .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-archive-posts .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-posts-nothing-found {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-loop-grid .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-loop-grid .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-loop-grid .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-posts .elementor-post__title,
.elementor-widget-posts .elementor-post__title a {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-posts .elementor-post__meta-data {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-posts .elementor-post__excerpt p {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-posts .elementor-post__read-more {
  color: var(--e-global-color-accent);
}

.elementor-widget-posts a.elementor-post__read-more {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-posts .elementor-post__card .elementor-post__badge {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-posts .elementor-pagination {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-posts .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-posts .e-load-more-message {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-portfolio a .elementor-portfolio-item__overlay {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-portfolio .elementor-portfolio-item__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-portfolio .elementor-portfolio__filter {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-portfolio .elementor-portfolio__filter.elementor-active {
  color: var(--e-global-color-primary);
}

.elementor-widget-gallery .elementor-gallery-item__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-gallery .elementor-gallery-item__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-gallery {
  --galleries-title-color-normal: var(--e-global-color-primary);
  --galleries-title-color-hover: var(--e-global-color-secondary);
  --galleries-pointer-bg-color-hover: var(--e-global-color-accent);
  --gallery-title-color-active: var(--e-global-color-secondary);
  --galleries-pointer-bg-color-active: var(--e-global-color-accent);
}

.elementor-widget-gallery .elementor-gallery-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-form .elementor-field-group > label,
.elementor-widget-form .elementor-field-subgroup label {
  color: var(--e-global-color-text);
}

.elementor-widget-form .elementor-field-group > label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-form .elementor-field-type-html {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-form .elementor-field-group .elementor-field {
  color: var(--e-global-color-text);
}

.elementor-widget-form .elementor-field-group .elementor-field,
.elementor-widget-form .elementor-field-subgroup label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-form .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-form .e-form__buttons__wrapper__button-next {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-form .elementor-button[type="submit"] {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-form .e-form__buttons__wrapper__button-previous {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-form .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-form .e-form__indicators__indicator,
.elementor-widget-form .e-form__indicators__indicator__label {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-form {
  --e-form-steps-indicator-inactive-primary-color: var(--e-global-color-text);
  --e-form-steps-indicator-active-primary-color: var(--e-global-color-accent);
  --e-form-steps-indicator-completed-primary-color: var(
    --e-global-color-accent
  );
  --e-form-steps-indicator-progress-color: var(--e-global-color-accent);
  --e-form-steps-indicator-progress-background-color: var(
    --e-global-color-text
  );
  --e-form-steps-indicator-progress-meter-color: var(--e-global-color-text);
}

.elementor-widget-form .e-form__indicators__indicator__progress__meter {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-login .elementor-field-group > a {
  color: var(--e-global-color-text);
}

.elementor-widget-login .elementor-field-group > a:hover {
  color: var(--e-global-color-accent);
}

.elementor-widget-login .elementor-form-fields-wrapper label {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-login .elementor-field-group .elementor-field {
  color: var(--e-global-color-text);
}

.elementor-widget-login .elementor-field-group .elementor-field,
.elementor-widget-login .elementor-field-subgroup label {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-login .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-login
  .elementor-widget-container
  .elementor-login__logged-in-message {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-slides .elementor-slide-heading {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-slides .elementor-slide-description {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-slides .elementor-slide-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-nav-menu .elementor-nav-menu .elementor-item {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item {
  color: var(--e-global-color-text);
  fill: var(--e-global-color-text);
}

.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:hover,
.elementor-widget-nav-menu
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-widget-nav-menu
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:focus {
  color: var(--e-global-color-accent);
  fill: var(--e-global-color-accent);
}

.elementor-widget-nav-menu
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:before,
.elementor-widget-nav-menu
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:after {
  background-color: var(--e-global-color-accent);
}

.elementor-widget-nav-menu .e--pointer-framed .elementor-item:before,
.elementor-widget-nav-menu .e--pointer-framed .elementor-item:after {
  border-color: var(--e-global-color-accent);
}

.elementor-widget-nav-menu {
  --e-nav-menu-divider-color: var(--e-global-color-text);
}

.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-item,
.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-sub-item {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-animated-headline .elementor-headline-dynamic-wrapper path {
  stroke: var(--e-global-color-accent);
}

.elementor-widget-animated-headline .elementor-headline-plain-text {
  color: var(--e-global-color-secondary);
}

.elementor-widget-animated-headline .elementor-headline {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-animated-headline {
  --dynamic-text-color: var(--e-global-color-secondary);
}

.elementor-widget-animated-headline .elementor-headline-dynamic-text {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-hotspot .widget-image-caption {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-hotspot {
  --hotspot-color: var(--e-global-color-primary);
  --hotspot-box-color: var(--e-global-color-secondary);
  --tooltip-color: var(--e-global-color-secondary);
}

.elementor-widget-hotspot .e-hotspot__label {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-hotspot .e-hotspot__tooltip {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-header {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-price {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-description {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-price-list .elementor-price-list-separator {
  border-bottom-color: var(--e-global-color-secondary);
}

.elementor-widget-price-table {
  --e-price-table-header-background-color: var(--e-global-color-secondary);
}

.elementor-widget-price-table .elementor-price-table__heading {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__subheading {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-table
  .elementor-price-table
  .elementor-price-table__price {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__original-price {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__period {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__features-list {
  --e-price-table-features-list-color: var(--e-global-color-text);
}

.elementor-widget-price-table .elementor-price-table__features-list li {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-price-table .elementor-price-table__features-list li:before {
  border-top-color: var(--e-global-color-text);
}

.elementor-widget-price-table .elementor-price-table__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-price-table .elementor-price-table__additional_info {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-price-table .elementor-price-table__ribbon-inner {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-flip-box
  .elementor-flip-box__front
  .elementor-flip-box__layer__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-flip-box
  .elementor-flip-box__front
  .elementor-flip-box__layer__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-flip-box
  .elementor-flip-box__back
  .elementor-flip-box__layer__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-flip-box
  .elementor-flip-box__back
  .elementor-flip-box__layer__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-flip-box .elementor-flip-box__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-call-to-action .elementor-cta__title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-call-to-action .elementor-cta__description {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-call-to-action .elementor-cta__button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-call-to-action .elementor-ribbon-inner {
  background-color: var(--e-global-color-accent);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-media-carousel .elementor-carousel-image-overlay {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__name {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-reviews .elementor-testimonial__header,
.elementor-widget-reviews .elementor-testimonial__name {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-reviews .elementor-testimonial__text {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-table-of-contents {
  --header-color: var(--e-global-color-secondary);
  --item-text-color: var(--e-global-color-text);
  --item-text-hover-color: var(--e-global-color-accent);
  --marker-color: var(--e-global-color-text);
}

.elementor-widget-table-of-contents .elementor-toc__header,
.elementor-widget-table-of-contents .elementor-toc__header-title {
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-table-of-contents .elementor-toc__list-item {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-countdown .elementor-countdown-item {
  background-color: var(--e-global-color-primary);
}

.elementor-widget-countdown .elementor-countdown-digits {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-countdown .elementor-countdown-label {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-countdown .elementor-countdown-expire--message {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-search-form
  input[type="search"].elementor-search-form__input {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-search-form .elementor-search-form__input,
.elementor-widget-search-form .elementor-search-form__icon,
.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button,
.elementor-widget-search-form
  .elementor-lightbox
  .dialog-lightbox-close-button:hover,
.elementor-widget-search-form.elementor-search-form--skin-full_screen
  input[type="search"].elementor-search-form__input {
  color: var(--e-global-color-text);
  fill: var(--e-global-color-text);
}

.elementor-widget-search-form .elementor-search-form__submit {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
  background-color: var(--e-global-color-secondary);
}

.elementor-widget-author-box .elementor-author-box__name {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-author-box .elementor-author-box__bio {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-author-box .elementor-author-box__button {
  color: var(--e-global-color-secondary);
  border-color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-author-box .elementor-author-box__button:hover {
  border-color: var(--e-global-color-secondary);
  color: var(--e-global-color-secondary);
}

.elementor-widget-post-navigation span.post-navigation__prev--label {
  color: var(--e-global-color-text);
}

.elementor-widget-post-navigation span.post-navigation__next--label {
  color: var(--e-global-color-text);
}

.elementor-widget-post-navigation span.post-navigation__prev--label,
.elementor-widget-post-navigation span.post-navigation__next--label {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-post-navigation span.post-navigation__prev--title,
.elementor-widget-post-navigation span.post-navigation__next--title {
  color: var(--e-global-color-secondary);
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-post-info .elementor-icon-list-item:not(:last-child):after {
  border-color: var(--e-global-color-text);
}

.elementor-widget-post-info .elementor-icon-list-icon i {
  color: var(--e-global-color-primary);
}

.elementor-widget-post-info .elementor-icon-list-icon svg {
  fill: var(--e-global-color-primary);
}

.elementor-widget-post-info .elementor-icon-list-text,
.elementor-widget-post-info .elementor-icon-list-text a {
  color: var(--e-global-color-secondary);
}

.elementor-widget-post-info .elementor-icon-list-item {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-sitemap .elementor-sitemap-title {
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-primary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-sitemap .elementor-sitemap-item,
.elementor-widget-sitemap span.elementor-sitemap-list,
.elementor-widget-sitemap .elementor-sitemap-item a {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-sitemap .elementor-sitemap-item {
  color: var(--e-global-color-text);
}

.elementor-widget-breadcrumbs {
  font-family: var(--e-global-typography-secondary-font-family), Sans-serif;
  font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-blockquote .elementor-blockquote__content {
  color: var(--e-global-color-text);
}

.elementor-widget-blockquote .elementor-blockquote__author {
  color: var(--e-global-color-secondary);
}

.elementor-widget-lottie {
  --caption-color: var(--e-global-color-text);
}

.elementor-widget-lottie .e-lottie__caption {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-title {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-videos-count {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side i {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side svg {
  fill: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-title-text a {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-duration {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-title-text {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-title-text
  a {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist
  .e-tabs-items-wrapper
  .e-tab-title:where(.e-active, :hover)
  .e-tab-duration {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-items-wrapper .e-section-title {
  color: var(--e-global-color-text);
}

.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-wrapper
  .e-inner-tab-title
  a {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  .e-inner-tab-text {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  button {
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-video-playlist
  .e-tabs-inner-tabs
  .e-inner-tabs-content-wrapper
  .e-inner-tab-content
  button:hover {
  color: var(--e-global-color-text);
}

.elementor-widget-paypal-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-paypal-button .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-stripe-button .elementor-button {
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-weight: var(--e-global-typography-accent-font-weight);
  background-color: var(--e-global-color-accent);
}

.elementor-widget-stripe-button .elementor-message {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-widget-progress-tracker .current-progress-percentage {
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
  font-weight: var(--e-global-typography-text-font-weight);
}

.elementor-26738 .elementor-element.elementor-element-72e8450 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e3f0d26
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b31ca4e
  > .elementor-container {
  max-width: 1230px;
}

.elementor-26738
  .elementor-element.elementor-element-b31ca4e:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-b31ca4e
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(90deg, #4417f9 0%, #a541fa 100%);
}

.elementor-26738 .elementor-element.elementor-element-b31ca4e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -2px;
  margin-bottom: 0px;
  padding: 13px 0px 32px 0px;
  z-index: 999;
}

.elementor-26738
  .elementor-element.elementor-element-b31ca4e
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-a2c2954
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1e27d81
  > .elementor-container {
  max-width: 1230px;
}

.elementor-26738 .elementor-element.elementor-element-1e27d81 {
  margin-top: 0px;
  margin-bottom: -21px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0b1e6b6
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-wrapper {
  max-width: 98%;
}

.elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-item {
  background-color: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 12px 12px 0px 12px;
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-item:not(:first-of-type) {
  margin-left: calc(5px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-item:not(:last-of-type) {
  margin-right: calc(5px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-item:not(:first-of-type) {
  margin-right: calc(5px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-item:not(:last-of-type) {
  margin-left: calc(5px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-digits {
  color: #dd248a;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5em;
}

.elementor-26738
  .elementor-element.elementor-element-080fd63
  .elementor-countdown-label {
  color: #1e1e23;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 2.2em;
}

.elementor-26738
  .elementor-element.elementor-element-080fd63
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2defe65
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3a7dcc3 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-3a7dcc3
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.elementor-26738
  .elementor-element.elementor-element-3a7dcc3
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 8px 0px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-bf61c55
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f8158af
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  fill: #000000;
  color: #000000;
  background-color: #ffe800;
  border-radius: 5px 5px 5px 5px;
  padding: 6px 15px 4px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-f8158af
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-f8158af
  .elementor-button:focus {
  color: #000000;
  background-color: #ffec2a;
}

.elementor-26738
  .elementor-element.elementor-element-f8158af
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-f8158af
  .elementor-button:focus
  svg {
  fill: #000000;
}

.elementor-26738
  .elementor-element.elementor-element-f8158af
  > .elementor-widget-container {
  margin: 3px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-338234b
  > .elementor-container {
  max-width: 1080px;
}

.elementor-26738
  .elementor-element.elementor-element-338234b:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-338234b
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #040b27;
}

.elementor-26738
  .elementor-element.elementor-element-338234b
  > .elementor-background-overlay {
  background-color: #1c1e1d;
  background-image: url("https://www.pabbly.com/wp-content/uploads/2020/08/Pabbly-Header-BG.jpg");
  background-position: center center;
  background-size: cover;
  opacity: 0.51;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-338234b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -2px;
  margin-bottom: 0px;
  padding: 15px 0px 8px 0px;
  z-index: 999;
}

.elementor-26738
  .elementor-element.elementor-element-af2367d
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f77cfbd
  > .elementor-widget-container {
  margin: -16px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5855bf8
  > .elementor-element-populated {
  margin: 0px 10px 0px 0px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a32d839 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-a32d839
  .elementor-heading-title {
  color: #ffef00;
  font-family: "Poppins", Sans-serif;
  font-size: 26px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-a32d839
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-wrapper {
  max-width: 36%;
}

.elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-item {
  background-color: #ffffff;
  border-radius: 5px 5px 5px 5px;
  padding: 12px 8px 1px 8px;
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-item:not(:first-of-type) {
  margin-left: calc(5px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-item:not(:last-of-type) {
  margin-right: calc(5px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-item:not(:first-of-type) {
  margin-right: calc(5px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-item:not(:last-of-type) {
  margin-left: calc(5px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-digits {
  color: #e71010;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 0.5em;
}

.elementor-26738
  .elementor-element.elementor-element-6d294d2
  .elementor-countdown-label {
  color: #212121;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.2em;
}

.elementor-26738
  .elementor-element.elementor-element-6d294d2
  > .elementor-widget-container {
  margin: -22px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4a14bd0 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-4a14bd0
  .elementor-heading-title {
  color: #ffffffab;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-4a14bd0
  > .elementor-widget-container {
  margin: -14px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3c75c63
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-aa5d89b {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-aa5d89b
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-aa5d89b
  > .elementor-widget-container {
  margin: -4px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4984b5b
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  fill: #000000;
  color: #000000;
  background-color: #ffef00;
  border-radius: 5px 5px 5px 5px;
  padding: 6px 30px 5px 30px;
}

.elementor-26738
  .elementor-element.elementor-element-4984b5b
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-4984b5b
  .elementor-button:focus {
  color: #000000;
  background-color: #ffec2a;
}

.elementor-26738
  .elementor-element.elementor-element-4984b5b
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-4984b5b
  .elementor-button:focus
  svg {
  fill: #000000;
}

.elementor-26738
  .elementor-element.elementor-element-4984b5b
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4abbbf8:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-4abbbf8
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-4abbbf8 {
  box-shadow: -1px 4px 4px 0px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
  z-index: 9999;
}

.elementor-26738
  .elementor-element.elementor-element-4abbbf8
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-cfc8d0d.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-cfc8d0d.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-cfc8d0d
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3c4df57
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-9b680ed.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-9b680ed.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-9b680ed
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-9b680ed
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-9b680ed
  > .elementor-background-slideshow {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9b680ed
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-bd71a63.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-bd71a63.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-bd71a63
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-menu-toggle {
  margin-left: auto;
  background-color: rgba(113, 35, 35, 0);
  border-width: 0px;
  border-radius: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu
  .elementor-item {
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-item {
  color: #000000;
  fill: #000000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 22px;
  padding-bottom: 22px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-item:hover,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-item:focus {
  color: #23a455;
  fill: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:after {
  background-color: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed
  .elementor-item:after {
  border-color: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed
  .elementor-item:before {
  border-width: 23px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed.e--animation-draw
  .elementor-item:before {
  border-width: 0 0 23px 23px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed.e--animation-draw
  .elementor-item:after {
  border-width: 23px 23px 0 0;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed.e--animation-corners
  .elementor-item:before {
  border-width: 23px 0 0 23px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-framed.e--animation-corners
  .elementor-item:after {
  border-width: 0 23px 23px 0;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-underline
  .elementor-item:after,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-overline
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-double-line
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .e--pointer-double-line
  .elementor-item:after {
  height: 23px;
}

.elementor-26738 .elementor-element.elementor-element-306c622 {
  --e-nav-menu-horizontal-menu-item-margin: calc(40px / 2);
  --nav-menu-icon-size: 29px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
  .elementor-nav-menu
  > li:not(:last-child) {
  margin-bottom: 40px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-menu-toggle {
  color: #000000;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a.highlighted,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-menu-toggle:hover {
  color: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a.highlighted {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  a.elementor-item-active {
  color: #0b31b1;
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  .elementor-item,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--dropdown
  .elementor-sub-item {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  .elementor-nav-menu--dropdown,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  box-shadow: 0px 6px 12px 0px rgba(10, 51, 176, 0.1);
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu--main
  > .elementor-nav-menu
  > li
  > .elementor-nav-menu--dropdown,
.elementor-26738
  .elementor-element.elementor-element-306c622
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  margin-top: 0px !important;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  div.elementor-menu-toggle:hover {
  color: #0a32b0;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  div.elementor-menu-toggle:hover
  svg {
  fill: #0a32b0;
}

.elementor-26738
  .elementor-element.elementor-element-306c622
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-175c4f6
  > .elementor-container {
  max-width: 1120px;
}

.elementor-26738
  .elementor-element.elementor-element-175c4f6:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-175c4f6
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-175c4f6 {
  box-shadow: 0px 2px 5px 0px rgba(12, 24, 72, 0.06);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 8px 0px 8px 0px;
  z-index: 990;
}

.elementor-26738
  .elementor-element.elementor-element-175c4f6
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-eb39e06.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-eb39e06.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-eb39e06
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-62532f3 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-62532f3 img {
  width: 66%;
}

.elementor-26738
  .elementor-element.elementor-element-62532f3
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-02fe9f1.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-02fe9f1.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-02fe9f1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-651fa60
  .elementor-heading-title {
  font-family: "unique-font", Sans-serif;
}

.elementor-26738
  .elementor-element.elementor-element-651fa60
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-831d71c.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-831d71c.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-4f3f2f5
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-19230ba.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-19230ba.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-19230ba
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-menu-toggle {
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0px;
  border-radius: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu
  .elementor-item {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  .elementor-item {
  color: #222222;
  fill: #222222;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 22px;
  padding-bottom: 22px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  .elementor-item:hover,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  .elementor-item:focus {
  color: #23a455;
  fill: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:after {
  background-color: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed
  .elementor-item:after {
  border-color: #23a455;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed
  .elementor-item:before {
  border-width: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed.e--animation-draw
  .elementor-item:before {
  border-width: 0 0 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed.e--animation-draw
  .elementor-item:after {
  border-width: 0px 0px 0 0;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed.e--animation-corners
  .elementor-item:before {
  border-width: 0px 0 0 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-framed.e--animation-corners
  .elementor-item:after {
  border-width: 0 0px 0px 0;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-underline
  .elementor-item:after,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-overline
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-double-line
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .e--pointer-double-line
  .elementor-item:after {
  height: 0px;
}

.elementor-26738 .elementor-element.elementor-element-537e2a0 {
  --e-nav-menu-horizontal-menu-item-margin: calc(24px / 2);
  --nav-menu-icon-size: 30px;
  z-index: 9;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
  .elementor-nav-menu
  > li:not(:last-child) {
  margin-bottom: 24px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  a,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-menu-toggle {
  color: #000000;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  a.highlighted,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-menu-toggle:hover {
  color: #2db500;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  .elementor-item,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  .elementor-sub-item {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  li:first-child
  a {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  li:last-child
  a {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--dropdown
  li:not(:last-child) {
  border-style: solid;
  border-color: #0c0c0c;
  border-bottom-width: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu--main
  > .elementor-nav-menu
  > li
  > .elementor-nav-menu--dropdown,
.elementor-26738
  .elementor-element.elementor-element-537e2a0
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  margin-top: 0px !important;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  div.elementor-menu-toggle:hover {
  color: #00ce1b;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  div.elementor-menu-toggle:hover
  svg {
  fill: #00ce1b;
}

.elementor-26738
  .elementor-element.elementor-element-537e2a0
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-bc-flex-widget
  .elementor-26738
  .elementor-element.elementor-element-9011f1b.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-9011f1b.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-9011f1b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 8px 22px 8px 22px;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-14051fa
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-14051fa
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 2px 0px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9078116
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-9078116:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-9078116
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-9078116
  > .elementor-background-overlay {
  background-image: url("https://www.pabbly.com/wp-content/uploads/2020/03/Untitled-3.jpg");
  opacity: 0;
  mix-blend-mode: luminosity;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-9078116
  .elementor-background-overlay {
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-9078116 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-76d5b43
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cf92829
  > .elementor-container {
  max-width: 1280px;
}

.elementor-26738 .elementor-element.elementor-element-cf92829 {
  padding: 32px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-347ae8f
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-347ae8f
  > .elementor-element-populated {
  text-align: left;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-c32f327 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-c32f327 img {
  width: 100%;
  opacity: 1;
}

.elementor-26738
  .elementor-element.elementor-element-c32f327
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8c6a64f
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8c6a64f
  > .elementor-element-populated {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-menu-toggle {
  margin-left: auto;
  background-color: rgba(255, 255, 255, 0.73);
  border-width: 1px;
  border-radius: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu
  .elementor-item {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 0.1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item {
  color: #222222;
  fill: #222222;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item:hover,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item.highlighted,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item:focus {
  color: #009cff;
  fill: #009cff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main:not(.e--pointer-framed)
  .elementor-item:after {
  background-color: #009cff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed
  .elementor-item:after {
  border-color: #009cff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-item.elementor-item-active {
  color: #009cff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed
  .elementor-item:before {
  border-width: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed.e--animation-draw
  .elementor-item:before {
  border-width: 0 0 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed.e--animation-draw
  .elementor-item:after {
  border-width: 0px 0px 0 0;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed.e--animation-corners
  .elementor-item:before {
  border-width: 0px 0 0 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-framed.e--animation-corners
  .elementor-item:after {
  border-width: 0 0px 0px 0;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-underline
  .elementor-item:after,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-overline
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-double-line
  .elementor-item:before,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .e--pointer-double-line
  .elementor-item:after {
  height: 0px;
}

.elementor-26738 .elementor-element.elementor-element-4feb5c0 {
  --e-nav-menu-horizontal-menu-item-margin: calc(5px / 2);
  --nav-menu-icon-size: 41px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
  .elementor-nav-menu
  > li:not(:last-child) {
  margin-bottom: 5px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-menu-toggle {
  color: #000000;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a.highlighted,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-menu-toggle:hover {
  color: #0029af;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a:hover,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a.elementor-item-active,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a.highlighted {
  background-color: rgba(10, 49, 177, 0.08);
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  .elementor-item,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  .elementor-sub-item {
  font-size: 12px;
  font-weight: 600;
  text-transform: none;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  .elementor-nav-menu--dropdown,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  box-shadow: 0px 6px 12px 0px rgba(10, 51, 176, 0.1);
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  a {
  padding-left: 0px;
  padding-right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--dropdown
  li:not(:last-child) {
  border-style: solid;
  border-color: #eaeaea;
  border-bottom-width: 1px;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu--main
  > .elementor-nav-menu
  > li
  > .elementor-nav-menu--dropdown,
.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  .elementor-nav-menu__container.elementor-nav-menu--dropdown {
  margin-top: 0px !important;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  div.elementor-menu-toggle {
  color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  div.elementor-menu-toggle
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  div.elementor-menu-toggle:hover {
  color: rgba(0, 206, 27, 0);
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  div.elementor-menu-toggle:hover
  svg {
  fill: rgba(0, 206, 27, 0);
}

.elementor-26738
  .elementor-element.elementor-element-4feb5c0
  > .elementor-widget-container {
  margin: 6px -10px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-20d0c58
  > .elementor-container {
  max-width: 1040px;
}

.elementor-26738
  .elementor-element.elementor-element-3d73bad
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-14eb141 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-14eb141 img {
  width: 30%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-304f9e3 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-304f9e3
  .elementor-heading-title {
  color: #606077;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-304f9e3
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-c06e75c
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d8d0625 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-d8d0625 img {
  width: 30%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-f203b7d {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f203b7d
  .elementor-heading-title {
  color: #606077;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-f203b7d
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-d7e0dff
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4338e8c {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-4338e8c img {
  width: 28%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-5f1bff9 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-5f1bff9
  .elementor-heading-title {
  color: #606077;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-5f1bff9
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 10px 0px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-b90ca14
  > .elementor-container {
  max-width: 1240px;
}

.elementor-26738 .elementor-element.elementor-element-b90ca14 {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6a41fd1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-6a41fd1 {
  z-index: 0;
}

.elementor-26738 .elementor-element.elementor-element-1dcdc80 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-1dcdc80
  .elementor-heading-title {
  color: #030025;
  font-family: "Inter", Sans-serif;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3em;
}

.elementor-26738
  .elementor-element.elementor-element-1dcdc80
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 60px 0px 60px;
}

.elementor-26738 .elementor-element.elementor-element-fdcdc35 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-fdcdc35
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-fdcdc35
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e1e6750:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-e1e6750
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-e1e6750
  > .elementor-background-overlay {
  opacity: 0;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-e1e6750 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 110px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9597439
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-dd4c6f6
  .elementor-wrapper {
  aspect-ratio: 16 / 9;
}

.elementor-26738
  .elementor-element.elementor-element-dd4c6f6
  .elementor-custom-embed-play
  i {
  color: #e40000;
  font-size: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-dd4c6f6
  .elementor-custom-embed-play
  svg {
  fill: #e40000;
  width: 0px;
  height: 0px;
}

#elementor-lightbox-dd4c6f6 {
  background-color: #f8f8f8;
}

#elementor-lightbox-dd4c6f6 .dialog-lightbox-close-button {
  color: rgba(27, 12, 12, 0);
}

#elementor-lightbox-dd4c6f6 .dialog-lightbox-close-button svg {
  fill: rgba(27, 12, 12, 0);
}

#elementor-lightbox-dd4c6f6 .dialog-lightbox-close-button:hover {
  color: #731f1f;
}

#elementor-lightbox-dd4c6f6 .dialog-lightbox-close-button:hover svg {
  fill: #731f1f;
}

.elementor-26738
  .elementor-element.elementor-element-dd4c6f6
  > .elementor-widget-container {
  margin: 0px 180px 0px 180px;
  padding: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #ffffff;
  border-radius: 14px 14px 14px 14px;
  box-shadow: 0px 30px 50px 0px rgba(70, 89, 119, 0.25);
}

.elementor-26738 .elementor-element.elementor-element-dd4c6f6 {
  z-index: 0;
}

.elementor-26738
  .elementor-element.elementor-element-7381530
  > .elementor-container {
  max-width: 780px;
}

.elementor-26738 .elementor-element.elementor-element-7381530 {
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-86130c4
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: #ffffff0d;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-86130c4
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(6px / 2);
  margin-left: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-6px / 2);
  margin-left: calc(-6px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-6px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-icon
  i {
  color: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-icon
  svg {
  fill: #006df9;
}

.elementor-26738 .elementor-element.elementor-element-5095bf4 {
  --e-icon-list-icon-size: 14px;
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-text {
  color: #030025;
  padding-left: 8px;
}

.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-5095bf4
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-8af982b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 00px;
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(6px / 2);
  margin-left: calc(6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-6px / 2);
  margin-left: calc(-6px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-6px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-6px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-icon
  i {
  color: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-icon
  svg {
  fill: #006df9;
}

.elementor-26738 .elementor-element.elementor-element-4fb8592 {
  --e-icon-list-icon-size: 14px;
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-text {
  color: #030025;
  padding-left: 8px;
}

.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-4fb8592
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-113f509
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-113f509 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9a801fa
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1bb866e
  .elementor-heading-title {
  font-family: "unique-font", Sans-serif;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-1bb866e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-5ff78a6
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-568ed8d
  > .elementor-container {
  max-width: 670px;
}

.elementor-26738 .elementor-element.elementor-element-568ed8d {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 15px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8a8bf59
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-bf7f3ea {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-bf7f3ea
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-bf7f3ea
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-bf7f3ea
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-bf7f3ea
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3b89bab
  > .elementor-container {
  max-width: 840px;
}

.elementor-26738 .elementor-element.elementor-element-3b89bab {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -45px;
  margin-bottom: 0px;
  padding: 0px 0px 50px 0px;
  z-index: 9;
}

.elementor-26738
  .elementor-element.elementor-element-3b89bab
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5daba1f:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9e9e9;
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 0px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 0px;
  padding: 20px 26px 15px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-5daba1f:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-5daba1f
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-3739cea {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5942926
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3dc66ca img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-cef26d5
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-019a05e
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-9af7d2a:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9e9e9;
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 5px 0px 5px;
  --e-column-margin-right: 5px;
  --e-column-margin-left: 5px;
  padding: 20px 26px 15px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-9af7d2a:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-9af7d2a
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-147ef71 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-24a9315
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4eb3f6f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-a5926ff
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-ec99240:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e9e9e9;
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 10px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 10px;
  padding: 20px 26px 15px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-ec99240:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-ec99240
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-00c9e89 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-19cce5e
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-705a3ad img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-d7d0737
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-b825203
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-923a252
  > .elementor-container {
  max-width: 840px;
}

.elementor-26738
  .elementor-element.elementor-element-923a252:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-923a252
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #fbfcff;
}

.elementor-26738 .elementor-element.elementor-element-923a252 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -45px;
  margin-bottom: 0px;
  padding: 0px 0px 50px 0px;
  z-index: 9;
}

.elementor-26738
  .elementor-element.elementor-element-923a252
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-7ef98ad:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 14px 0px 0px;
  --e-column-margin-right: 14px;
  --e-column-margin-left: 0px;
  padding: 20px 26px 20px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-7ef98ad:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-7ef98ad
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-a7fe9e9 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-754a815
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e2e07a6 img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-482effb
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-9dcfa66
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-3b7f7fd:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 14px 0px 0px;
  --e-column-margin-right: 14px;
  --e-column-margin-left: 0px;
  padding: 20px 26px 20px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-3b7f7fd:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-3b7f7fd
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-92a4a66 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d52666f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a39a31c img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-3ec4c29
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-70b867d
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-5715a03:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 14px 0px 0px;
  --e-column-margin-right: 14px;
  --e-column-margin-left: 0px;
  padding: 20px 26px 20px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-5715a03:hover
  > .elementor-element-populated {
  box-shadow: 0px 8px 20px 0px
    rgba(0, 11.000000000000009, 40.99999999999999, 0.16);
}

.elementor-26738
  .elementor-element.elementor-element-5715a03
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-4bb892d {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e3b161f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-ae15960 img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-f6640d7
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-5a24440
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
}

.elementor-26738
  .elementor-element.elementor-element-7fe2712
  > .elementor-container {
  max-width: 800px;
}

.elementor-26738
  .elementor-element.elementor-element-7fe2712:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-7fe2712
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #053099;
}

.elementor-26738
  .elementor-element.elementor-element-7fe2712
  > .elementor-background-overlay {
  background-color: #fa1616;
  opacity: 0.06;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-7fe2712 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -91px;
  margin-bottom: 0px;
  padding: 110px 0px 50px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-032fdf3
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-383cea8 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-383cea8
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-383cea8
  > .elementor-widget-container {
  margin: -5px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4ef924a {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d47248c
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4fc5ada {
  text-align: right;
}

.elementor-26738 .elementor-element.elementor-element-4fc5ada img {
  width: 26%;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #ffffff;
  border-radius: 500px 500px 500px 500px;
}

.elementor-26738
  .elementor-element.elementor-element-4fc5ada
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3a5c694
  > .elementor-element-populated {
  margin: 0px 0px 0px 24px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 24px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3f9cb49 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-3f9cb49
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-3f9cb49
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 12px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0005387
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-0005387:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-0005387
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-0005387 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 60px 0px 70px 0px;
  z-index: 0;
}

.elementor-26738
  .elementor-element.elementor-element-0005387
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-31dfaaa
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-dad665d {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-dad665d
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-dad665d
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 100px 0px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-0d4189c
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-0d4189c {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-90aa1e8
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-90aa1e8
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-90aa1e8
  > .elementor-background-slideshow {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-90aa1e8
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-90aa1e8
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-f48bd4d {
  --e-image-carousel-slides-to-show: 5;
}

.elementor-26738 .elementor-element.elementor-element-f48bd4d .swiper-wrapper {
  display: flex;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-f48bd4d
  .elementor-image-carousel-wrapper
  .elementor-image-carousel
  .swiper-slide-image {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e5edff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-f48bd4d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8a49bf8
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738
  .elementor-element.elementor-element-8a49bf8:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-8a49bf8
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-8a49bf8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 100px 0px 100px 0px;
  z-index: 0;
}

.elementor-26738
  .elementor-element.elementor-element-8a49bf8
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-96030b3
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-32c0dc5 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-32c0dc5
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-32c0dc5
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 120px 0px 120px;
}

.elementor-26738 .elementor-element.elementor-element-003e4d4 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-003e4d4
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-003e4d4
  > .elementor-widget-container {
  margin: -5px 0px 0px 0px;
  padding: 0px 80px 0px 80px;
}

.elementor-26738 .elementor-element.elementor-element-f4f2f58 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f4f2f58
  .elementor-heading-title {
  color: #38373c;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f4f2f58
  > .elementor-widget-container {
  margin: -5px 160px 0px 160px;
  padding: 12px 15px 12px 15px;
  background-color: #e7f2ff;
  border-radius: 50px 50px 50px 50px;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .elementor-main-swiper {
  height: 243px;
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2.elementor-skin-slideshow
  .elementor-main-swiper {
  margin-bottom: 16px;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .elementor-main-swiper
  .swiper-slide {
  border-width: 1px 1px 1px 1px;
  border-radius: 10px;
  border-color: #e3e3e3;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .elementor-swiper-button {
  font-size: 20px;
  color: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .elementor-swiper-button
  svg {
  fill: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-pagination-bullet {
  height: 10px;
  width: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-container-horizontal
  .swiper-pagination-progressbar {
  height: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-pagination-fraction {
  font-size: 10px;
  color: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background-color: #cce1fc;
  opacity: 1;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-pagination-bullet-active,
.elementor-26738
  .elementor-element.elementor-element-e4828e2
  .swiper-pagination-progressbar-fill {
  background-color: #006df9;
}

.elementor-26738
  .elementor-element.elementor-element-e4828e2
  > .elementor-widget-container {
  margin: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-f530c26
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-f530c26
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a3830e4 {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-a3830e4
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-a3830e4
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-a3830e4
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-a3830e4
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cae6dca
  > .elementor-container {
  max-width: 1220px;
}

.elementor-26738
  .elementor-element.elementor-element-cae6dca:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-cae6dca
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738
  .elementor-element.elementor-element-cae6dca
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-cae6dca {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0px 80px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-35452ed
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9b5534c
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0bf3e71 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-0bf3e71
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0bf3e71
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 80px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8d34453 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-8d34453
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8d34453
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-38578b7
  > .elementor-container {
  max-width: 1220px;
}

.elementor-26738 .elementor-element.elementor-element-38578b7 {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c651dee
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 0px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c651dee
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-7d6f1b0 img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738 .elementor-element.elementor-element-f146989 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f146989
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-f146989
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-27ae495 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-27ae495
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-27ae495
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d961901
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 5px 0px 5px;
  --e-column-margin-right: 5px;
  --e-column-margin-left: 5px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d961901
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-e753f43 img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738 .elementor-element.elementor-element-26ebfe3 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-26ebfe3
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-26ebfe3
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-224937c {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-224937c
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-224937c
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4d019e9
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 10px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 10px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4d019e9
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-94ca9df img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738 .elementor-element.elementor-element-5804030 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-5804030
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-5804030
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-db0c762 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-db0c762
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-db0c762
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-705e3fe
  > .elementor-container {
  max-width: 1140px;
}

.elementor-26738 .elementor-element.elementor-element-705e3fe {
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0431c15
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 26px 0px 0px;
  --e-column-margin-right: 26px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0431c15
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-db3ec95 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-db3ec95
  .elementor-heading-title {
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-db3ec95
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-c838822 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-c838822
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-c838822
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4aeb0ab img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-f151bce
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 13px 0px 13px;
  --e-column-margin-right: 13px;
  --e-column-margin-left: 13px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f151bce
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-a9274c2 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-a9274c2
  .elementor-heading-title {
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-a9274c2
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d83f130 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-d83f130
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-d83f130
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-5de7ca5 img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-b087e86
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 26px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 26px;
  padding: 0px 0px 4px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b087e86
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-d26a5c7 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-d26a5c7
  .elementor-heading-title {
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-d26a5c7
  > .elementor-widget-container {
  margin: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-5cf0e07 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-5cf0e07
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.7em;
  letter-spacing: 0.4px;
}

.elementor-26738
  .elementor-element.elementor-element-5cf0e07
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d54c1cb img {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-230d715
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-230d715 {
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9b8424d
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9b8424d
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-0e7dced
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-0e7dced
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0e631dd {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-0e631dd
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-0e631dd
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-0e631dd
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-0e631dd
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-29cf370
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-29cf370:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-29cf370
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-29cf370 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 80px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-29cf370
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-ac85ed5 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-ac85ed5
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ac85ed5
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 80px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f1a2eee {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f1a2eee
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f1a2eee
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5b83898:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-5b83898
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #053099;
}

.elementor-26738
  .elementor-element.elementor-element-5b83898
  > .elementor-background-overlay {
  background-color: #fa1616;
  background-image: url("https://www.pabbly.com/wp-content/uploads/2021/09/Apps-scaled.jpg");
  opacity: 0.06;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-5b83898 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 100px 0px 120px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-19d27a4
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4280ec0 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-4280ec0
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4280ec0
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e4d5717 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-e4d5717
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-e4d5717
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d1d390e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-17881a6
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-17881a6:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-17881a6
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-17881a6 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 80px 0px;
  z-index: 11;
}

.elementor-26738
  .elementor-element.elementor-element-17881a6
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-dee14df:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.6679392993472688, 29.788790458312523, 69.75000000000004, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -50px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-dee14df
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-5b39d92 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 00px 20px 00px;
}

.elementor-26738
  .elementor-element.elementor-element-5b39d92
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-8708740
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-1ade038 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-1ade038 img {
  width: 30%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-f76182d {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f76182d
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3em;
}

.elementor-26738
  .elementor-element.elementor-element-f76182d
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-dd6ad26
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3d67e02 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-3d67e02 img {
  width: 35%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738
  .elementor-element.elementor-element-3d67e02
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2930ef9 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-2930ef9
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3em;
}

.elementor-26738
  .elementor-element.elementor-element-2930ef9
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b050374
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8b5edc2 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-8b5edc2 img {
  width: 30%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738 .elementor-element.elementor-element-fdf8658 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-fdf8658
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3em;
}

.elementor-26738
  .elementor-element.elementor-element-fdf8658
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 60px 0px 60px;
}

.elementor-26738
  .elementor-element.elementor-element-0247815
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-0247815:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-0247815
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-0247815 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 30px 0px 90px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0247815
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5c9c563
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5d14000
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738 .elementor-element.elementor-element-5d14000 {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c7f9b98
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-5283679 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-5283679
  .elementor-heading-title {
  color: #040125;
  font-family: "Inter", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5283679
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-15de290.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-15de290.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-15de290.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 7%;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-15de290
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 15px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 6%;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-74d6086
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 7%;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-807ef7c
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 6%;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-e4f96c2
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-980badf.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-980badf.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-980badf.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 7%;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-980badf
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61.elementor-position-right
  .elementor-image-box-img {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61.elementor-position-left
  .elementor-image-box-img {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 6%;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #2b2a35;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  .elementor-image-box-description {
  color: #514f55;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-7082c61
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 15px 6px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-abd07b7
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-abd07b7
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d97a27b {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-d97a27b
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-d97a27b
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-d97a27b
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-d97a27b
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-02aef26
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-81b1146
  > .elementor-widget-container {
  margin: -10px -10px 0px -5px;
}

.elementor-26738
  .elementor-element.elementor-element-30d307f
  > .elementor-container {
  max-width: 1170px;
}

.elementor-26738
  .elementor-element.elementor-element-30d307f:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-30d307f
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-30d307f
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-30d307f {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 90px 0px 80px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-938646a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7e16edb {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-7e16edb
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7e16edb
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 100px 0px 100px;
}

.elementor-26738 .elementor-element.elementor-element-e3516e1 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-e3516e1
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-e3516e1
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 140px 0px 140px;
}

.elementor-26738
  .elementor-element.elementor-element-3ccda14
  > .elementor-container {
  max-width: 1150px;
}

.elementor-26738 .elementor-element.elementor-element-3ccda14 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ae4eace:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-ae4eace:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-ae4eace
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-686143f img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-22ef69c {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-22ef69c
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-22ef69c
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-66635c5 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-66635c5
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-66635c5
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-66635c5 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-66635c5 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e3f0794:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-e3f0794:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-e3f0794
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-fa712cf img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-5c234a8 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-5c234a8
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-5c234a8
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9707166 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9707166
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9707166
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-9707166 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-9707166 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b24e7cf:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-b24e7cf:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-b24e7cf
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-ae08d71 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-5a952e8 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-5a952e8
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-5a952e8
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a202f2a {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a202f2a
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a202f2a
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-a202f2a {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-a202f2a {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8d22d4e:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-8d22d4e:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-8d22d4e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-d8f2d3e img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-83f757a {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-83f757a
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-83f757a
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-df67e30 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-df67e30
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-df67e30
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-df67e30 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-df67e30 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2d9ba0e:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-2d9ba0e:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-2d9ba0e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-2e2619b img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-8e29811 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-8e29811
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-8e29811
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7567c3a {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7567c3a
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7567c3a
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-7567c3a {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-7567c3a {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6f00f72:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-6f00f72:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-6f00f72
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-784b4ec img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-97ecb10 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-97ecb10
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-97ecb10
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9f18a6e {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9f18a6e
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9f18a6e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-9f18a6e {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-9f18a6e {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c5e2059
  > .elementor-container {
  max-width: 1150px;
}

.elementor-26738 .elementor-element.elementor-element-c5e2059 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 26px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8f91fba:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-8f91fba:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-8f91fba
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-e6f6cd5 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-4b2fa64 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-4b2fa64
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-4b2fa64
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-ede6ee3 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ede6ee3
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ede6ee3
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-ede6ee3 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-ede6ee3 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0df538e:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-0df538e:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-0df538e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-3a75b3b img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-f813b19 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f813b19
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-f813b19
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a6a7e8c {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a6a7e8c
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a6a7e8c
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-a6a7e8c {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-a6a7e8c {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-141e689:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-141e689:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-141e689
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-b16b000 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-f8a0dcb {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f8a0dcb
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-f8a0dcb
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-945105b {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-945105b
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-945105b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-945105b {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-945105b {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-87fe43d:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-87fe43d:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-87fe43d
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-26eeb06 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-2a98a91 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-2a98a91
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-2a98a91
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-519d2cb {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-519d2cb
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-519d2cb
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-519d2cb {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-519d2cb {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0ce7aae:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-0ce7aae:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-0ce7aae
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-5868060 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-f3c4574 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f3c4574
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-f3c4574
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-cd289ac {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cd289ac
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cd289ac
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-cd289ac {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-cd289ac {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ea2a7fa:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-ea2a7fa:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-ea2a7fa
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-daae3df img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-13dcb24 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-13dcb24
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-13dcb24
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-c2161e1 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c2161e1
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c2161e1
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-c2161e1 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-c2161e1 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7ab63b5
  > .elementor-container {
  max-width: 1150px;
}

.elementor-26738 .elementor-element.elementor-element-7ab63b5 {
  padding: 26px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3c5e770:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3c5e770:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-3c5e770
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-cac5591 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-1a635dd {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-1a635dd
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-1a635dd
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-823e1ca {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-823e1ca
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-823e1ca
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-823e1ca {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-823e1ca {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f16fead:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-f16fead:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-f16fead
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-9a6b9dd img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-b9b56da {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-b9b56da
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-b9b56da
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7bc89cb {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7bc89cb
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7bc89cb
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-7bc89cb {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-7bc89cb {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-bea19cf:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-bea19cf:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-bea19cf
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-d77c142 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-e79d451 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-e79d451
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-e79d451
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-1299724 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1299724
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1299724
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-1299724 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-1299724 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f790560:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-f790560:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-f790560
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-9c9582f img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-7d654a2 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-7d654a2
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-7d654a2
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-faa1a03 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-faa1a03
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-faa1a03
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-faa1a03 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-faa1a03 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0cb19f1:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-0cb19f1:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-0cb19f1
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-150d13c img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-c4c201b {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-c4c201b
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-c4c201b
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f823865 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f823865
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f823865
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-f823865 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-f823865 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d26bfdd:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-element-populated {
  box-shadow: 0px 0px 30px 0px
    rgba(0, 84.78260869565219, 155.99999999999997, 0.11);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 30px 5px 0px 5px;
}

.elementor-26738
  .elementor-element.elementor-element-d26bfdd:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 35px 0px
    rgba(2.000000000000011, 27.00000000000012, 123, 0.11);
}

.elementor-26738
  .elementor-element.elementor-element-d26bfdd
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-350aae3 img {
  width: 26%;
}

.elementor-26738 .elementor-element.elementor-element-8ed986d {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-8ed986d
  .elementor-heading-title {
  color: #131017;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-8ed986d
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-acf46a2 {
  text-align: center;
  width: initial;
  max-width: initial;
  top: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-acf46a2
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-acf46a2
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 3px 8px 3px 8px;
  background-color: #66a801;
  border-radius: 6px 0px 6px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-acf46a2 {
  left: 0px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-acf46a2 {
  right: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8a12b68
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-8a12b68 {
  padding: 40px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-47c3efa
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-47c3efa
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-e9ec5c4
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-da7326d {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-da7326d
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-da7326d
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-da7326d
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-da7326d
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-aeaf2d5
  > .elementor-container {
  max-width: 1150px;
}

.elementor-26738
  .elementor-element.elementor-element-aeaf2d5:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-aeaf2d5
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738
  .elementor-element.elementor-element-aeaf2d5
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-aeaf2d5 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 80px 0px 180px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7386036
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-bd568cc {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-bd568cc
  .elementor-heading-title {
  color: #030025;
  font-family: "Inter", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-bd568cc
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-cc7b540 img {
  width: 100%;
}

.elementor-26738 .elementor-element.elementor-element-cc7b540 {
  width: var(--container-widget-width, 44px);
  max-width: 44px;
  --container-widget-width: 44px;
  --container-widget-flex-grow: 0;
  top: 3px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-cc7b540 {
  left: 1018px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-cc7b540 {
  right: 1018px;
}

.elementor-26738 .elementor-element.elementor-element-446fa26 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-446fa26
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-446fa26
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 150px 0px 150px;
}

.elementor-26738 .elementor-element.elementor-element-74f0e5f img {
  width: 80%;
}

.elementor-26738
  .elementor-element.elementor-element-74f0e5f
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-77dfe68 img {
  width: 80%;
}

.elementor-26738
  .elementor-element.elementor-element-77dfe68
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d3b06fa img {
  width: 86%;
}

.elementor-26738
  .elementor-element.elementor-element-d3b06fa
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-564809e {
  text-align: right;
  width: var(--container-widget-width, 438px);
  max-width: 438px;
  --container-widget-width: 438px;
  --container-widget-flex-grow: 0;
  top: 330px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-564809e {
  left: 842px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-564809e {
  right: 842px;
}

.elementor-26738 .elementor-element.elementor-element-1999313 img {
  width: 80%;
}

.elementor-26738
  .elementor-element.elementor-element-1999313
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-4be8fb4
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9041b17 {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-9041b17
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-9041b17
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-9041b17
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-9041b17
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5a9f96b
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-5a9f96b:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-5a9f96b
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-5a9f96b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 80px 0px;
  z-index: 11;
}

.elementor-26738
  .elementor-element.elementor-element-5a9f96b
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-91da731:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.999999999999993, 30.00000000000002, 70, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -84px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-91da731
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-6b26914 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 40px 20px 40px;
}

.elementor-26738
  .elementor-element.elementor-element-6b26914
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-ac4428a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f634246 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-f634246 img {
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-73dd5e5
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 26px;
}

.elementor-26738 .elementor-element.elementor-element-f9ab24f {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-f9ab24f img {
  width: 12%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738
  .elementor-element.elementor-element-f9ab24f
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-1bd1532 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-1bd1532
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
}

.elementor-26738
  .elementor-element.elementor-element-1bd1532
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f196242 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-f196242
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-f196242
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ef1fc78
  > .elementor-container {
  max-width: 1400px;
}

.elementor-26738
  .elementor-element.elementor-element-ef1fc78:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-ef1fc78
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-ef1fc78
  > .elementor-background-overlay {
  opacity: 0.4;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-ef1fc78 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 100px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9725ae0
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-25e010b {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-25e010b
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-25e010b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0850417 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-0850417
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0850417
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 250px 0px 250px;
}

.elementor-26738
  .elementor-element.elementor-element-f3e5e9b
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-f3e5e9b {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-00086bf
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 16px 50px 30px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-00086bf:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-00086bf
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f
  .elementor-image-box-title {
  margin-bottom: 14px;
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-0f84c9f
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-78df82e
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 16px 10px 30px 50px;
}

.elementor-26738
  .elementor-element.elementor-element-78df82e:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-78df82e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #111111;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-88998b6
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-18588ec
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-18588ec {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ce69bda
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 50px 30px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-ce69bda:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-ce69bda
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716
  .elementor-image-box-title {
  margin-bottom: 14px;
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-63a9716
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-1857916
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 10px 30px 50px;
}

.elementor-26738
  .elementor-element.elementor-element-1857916:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-1857916
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f
  .elementor-image-box-title {
  margin-bottom: 14px;
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-8512f3f
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-a039127
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-a039127 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f0a04fa
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 2px 0px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 50px 30px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-f0a04fa:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-f0a04fa
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: #111111;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-0f185c5
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-c3bd2c3
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #efefef;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 40px 10px 30px 50px;
}

.elementor-26738
  .elementor-element.elementor-element-c3bd2c3:hover
  > .elementor-element-populated {
  box-shadow: 0px 15px 30px 0px rgba(0, 3, 16, 0.12);
}

.elementor-26738
  .elementor-element.elementor-element-c3bd2c3
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9.elementor-position-right
  .elementor-image-box-img {
  margin-left: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9.elementor-position-left
  .elementor-image-box-img {
  margin-right: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 18px;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 11%;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9
  .elementor-image-box-img
  img {
  transition-duration: 0s;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9
  .elementor-image-box-wrapper {
  text-align: justify;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9
  .elementor-image-box-title {
  margin-bottom: 14px;
  color: #111111;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.8em;
}

.elementor-26738
  .elementor-element.elementor-element-a74fde9
  .elementor-image-box-description {
  color: #242729ed;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
}

.elementor-26738
  .elementor-element.elementor-element-5e39cc7
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-5e39cc7 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9a289ac
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-9a289ac
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-9a289ac
  > .elementor-background-slideshow {
  border-radius: 9px 9px 9px 9px;
}

.elementor-26738
  .elementor-element.elementor-element-9a289ac
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 30px 0px 0px;
  --e-column-margin-right: 30px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9a289ac
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-2d99c0b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d4600f0 {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-d4600f0
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-d4600f0
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-d4600f0
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-d4600f0
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2939bfa
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-2939bfa:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-2939bfa
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738
  .elementor-element.elementor-element-2939bfa
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-2939bfa {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 80px 0px 30px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3a5a805
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-953fad0
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cdd150c
  > .elementor-container {
  max-width: 900px;
}

.elementor-26738 .elementor-element.elementor-element-cdd150c {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-88d5a9a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-80430d8 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-80430d8
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-80430d8
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f6ec3ff {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-f6ec3ff
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f6ec3ff
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 12px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0cdca29:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-0cdca29
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-0cdca29 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -10px;
  margin-bottom: 0px;
  padding: 0px 0px 100px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0cdca29
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-210439b
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-210439b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3edf49a
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-3edf49a:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-3edf49a
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-3edf49a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 90px 0px 190px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3edf49a
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-d5e0b09
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8999f8e
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738 .elementor-element.elementor-element-8999f8e {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e229ad7
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4c579e4 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-4c579e4
  .elementor-heading-title {
  color: #040125;
  font-family: "Inter", Sans-serif;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4c579e4
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3f9d252 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-3f9d252
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3f9d252
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-33d1b88
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738 .elementor-element.elementor-element-33d1b88 {
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f4cbf5f:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px rgba(0, 20.000000000000057, 70, 0.14);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 20px 0px 0px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 0px;
  padding: 20px 20px 25px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-f4cbf5f:hover
  > .elementor-element-populated {
  box-shadow: 0px 12px 25px 0px rgba(0, 20.000000000000057, 70, 0.19);
}

.elementor-26738
  .elementor-element.elementor-element-f4cbf5f
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e44db44
  > .elementor-widget-container {
  padding: 40px 50px 40px 50px;
  background-color: #eff4f8;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738 .elementor-element.elementor-element-7f1138d {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-7f1138d
  .elementor-heading-title {
  color: #868686;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7f1138d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4c9847a {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-4c9847a
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4c9847a
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3637700:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px
    rgba(0, 20.000000000000036, 69.99999999999993, 0.14);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 10px 0px 10px;
  --e-column-margin-right: 10px;
  --e-column-margin-left: 10px;
  padding: 20px 20px 25px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3637700:hover
  > .elementor-element-populated {
  box-shadow: 0px 12px 25px 0px rgba(0, 20.000000000000057, 70, 0.19);
}

.elementor-26738
  .elementor-element.elementor-element-3637700
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-cf270b6
  > .elementor-widget-container {
  padding: 40px 50px 40px 50px;
  background-color: #eff4f8;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738 .elementor-element.elementor-element-4a63876 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-4a63876
  .elementor-heading-title {
  color: #868686;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4a63876
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-98ef103 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-98ef103
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-98ef103
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6cc04d7:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-element-populated {
  box-shadow: 0px 6px 12px 0px
    rgba(0, 20.000000000000036, 69.99999999999993, 0.14);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 20px 20px 25px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-6cc04d7:hover
  > .elementor-element-populated {
  box-shadow: 0px 12px 25px 0px rgba(0, 20.000000000000057, 70, 0.19);
}

.elementor-26738
  .elementor-element.elementor-element-6cc04d7
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-086491f
  > .elementor-widget-container {
  padding: 40px 50px 40px 50px;
  background-color: #eff4f8;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738 .elementor-element.elementor-element-645e8c0 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-645e8c0
  .elementor-heading-title {
  color: #868686;
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-645e8c0
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-cd9c514 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-cd9c514
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cd9c514
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-531afff
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738 .elementor-element.elementor-element-531afff {
  padding: 40px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d5b10a9
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-ca710a9
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-ca710a9
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0627ce3 {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-0627ce3
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-0627ce3
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-0627ce3
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-0627ce3
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a3a4550
  > .elementor-container {
  max-width: 1120px;
}

.elementor-26738
  .elementor-element.elementor-element-a3a4550:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-a3a4550
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-a3a4550 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 80px 0px;
  z-index: 11;
}

.elementor-26738
  .elementor-element.elementor-element-a3a4550
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-da1596d:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.6679392993472688, 29.788790458312523, 69.75000000000004, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -100px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-da1596d
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-2b6826a {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 40px 20px 40px;
}

.elementor-26738
  .elementor-element.elementor-element-2b6826a
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-b92ffa9
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-55a8cf8 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-55a8cf8 img {
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-f1b3e03
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 26px;
}

.elementor-26738 .elementor-element.elementor-element-0b7e195 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-0b7e195 img {
  width: 12%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738
  .elementor-element.elementor-element-0b7e195
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-ac0480a {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-ac0480a
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
}

.elementor-26738
  .elementor-element.elementor-element-ac0480a
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0366634 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-0366634
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-0366634
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d766607
  > .elementor-container {
  max-width: 1170px;
}

.elementor-26738
  .elementor-element.elementor-element-d766607:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-d766607
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738
  .elementor-element.elementor-element-d766607
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-d766607 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 0px 100px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8ee051f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-359836d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2891a92 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-2891a92
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2891a92
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 5px 200px 0px 200px;
}

.elementor-26738 .elementor-element.elementor-element-1570401 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-1570401
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1570401
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 160px 0px 160px;
}

.elementor-26738 .elementor-element.elementor-element-e7230db {
  text-align: left;
  width: var(--container-widget-width, 1000px);
  max-width: 1000px;
  --container-widget-width: 1000px;
  --container-widget-flex-grow: 0;
  top: 0px;
}

.elementor-26738 .elementor-element.elementor-element-e7230db img {
  width: 6%;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-e7230db {
  left: 150px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-e7230db {
  right: 150px;
}

.elementor-26738
  .elementor-element.elementor-element-df5a44b
  > .elementor-widget-container {
  margin: 0px 0px 0px 26px;
}

.elementor-26738
  .elementor-element.elementor-element-282a056
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-282a056 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-adc5a17
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-0b4fb11
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3d43fee {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-3d43fee
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-3d43fee
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-3d43fee
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-3d43fee
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-57b7ae0:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-57b7ae0
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-57b7ae0 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 100px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-57b7ae0
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-924b643
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-253087c {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-253087c
  .elementor-heading-title {
  color: #030025;
  font-family: "Inter", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-253087c
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-6678b7a {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-6678b7a
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6678b7a
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 60px 0px 60px;
}

.elementor-26738
  .elementor-element.elementor-element-8aecde4
  > .elementor-container {
  max-width: 1200px;
}

.elementor-26738
  .elementor-element.elementor-element-8aecde4:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-8aecde4
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-8aecde4 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 40px 0px 200px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8aecde4
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-871ab2a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9381eb3 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-9381eb3
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 46px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-49808eb:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-element-populated {
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-color: #2785ff;
  box-shadow: 0px 10px 40px 0px
    rgba(0, 6.999999999999998, 39.99999999999993, 0.13);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px -2px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: -2px;
  padding: 20px 30px 25px 30px;
}

.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-49808eb
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-db14a00 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-00bbf72
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d2a6c01 {
  text-align: right;
}

.elementor-26738 .elementor-element.elementor-element-d2a6c01 img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-5de5af1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 16px;
}

.elementor-26738
  .elementor-element.elementor-element-bae8786
  .elementor-heading-title {
  color: #222222;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.elementor-26738
  .elementor-element.elementor-element-77c83d2
  .elementor-heading-title {
  color: #23b377;
  font-family: "Inter", Sans-serif;
  font-size: 34px;
  font-weight: bold;
}

.elementor-26738
  .elementor-element.elementor-element-77c83d2
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8de69c8 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-8de69c8
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8de69c8
  > .elementor-widget-container {
  margin: 25px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(4px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(4px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(4px / 2);
  margin-left: calc(4px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-4px / 2);
  margin-left: calc(-4px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-4px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-4px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-icon
  i {
  color: #29c488;
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-icon
  svg {
  fill: #29c488;
}

.elementor-26738 .elementor-element.elementor-element-a7f14cb {
  --e-icon-list-icon-size: 14px;
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-text {
  color: #040125;
  padding-left: 12px;
}

.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-a7f14cb
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.elementor-26738 .elementor-element.elementor-element-dca485e {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4c28066
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button {
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #2785ff;
  border-radius: 9px 9px 9px 9px;
  padding: 14px 24px 14px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button:focus {
  color: #ffffff;
  background-color: #2785ff;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-eb164cf
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-eb164cf
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b4a92a2
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 8px;
}

.elementor-26738 .elementor-element.elementor-element-78cd18d {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-78cd18d img {
  width: 18%;
}

.elementor-26738
  .elementor-element.elementor-element-72239b9
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-72239b9:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-72239b9
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-72239b9 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
  z-index: 99;
}

.elementor-26738
  .elementor-element.elementor-element-72239b9
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-b8713ca:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.6679392993472688, 29.788790458312523, 69.75000000000004, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -90px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-b8713ca
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-ba4404e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 40px 20px 40px;
}

.elementor-26738
  .elementor-element.elementor-element-ba4404e
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-f02a05e
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d69a9d3 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-d69a9d3 img {
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-ff8fb1b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 26px;
}

.elementor-26738 .elementor-element.elementor-element-6a0c028 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-6a0c028 img {
  width: 12%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738
  .elementor-element.elementor-element-6a0c028
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f14f82c {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-f14f82c
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
}

.elementor-26738
  .elementor-element.elementor-element-f14f82c
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d21c089 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-d21c089
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-d21c089
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b70e9c7
  > .elementor-container {
  max-width: 1190px;
}

.elementor-26738
  .elementor-element.elementor-element-b70e9c7:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-b70e9c7
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738
  .elementor-element.elementor-element-b70e9c7
  > .elementor-background-overlay {
  opacity: 0.3;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-b70e9c7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 0px 0px;
  z-index: 90;
}

.elementor-26738
  .elementor-element.elementor-element-2d471c9
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d58ac1a
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-66454e6
  > .elementor-container {
  max-width: 900px;
}

.elementor-26738 .elementor-element.elementor-element-66454e6 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3a9882d
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e9d104d {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-e9d104d
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e9d104d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-417c5bb
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e870c5b {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-e870c5b
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e870c5b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 80px 0px 80px;
}

.elementor-26738
  .elementor-element.elementor-element-561afc3
  > .elementor-container {
  max-width: 680px;
}

.elementor-26738 .elementor-element.elementor-element-561afc3 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 0px 20px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-561afc3
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-c9a3970
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f4295ad {
  text-align: left;
  width: var(--container-widget-width, 231px);
  max-width: 231px;
  --container-widget-width: 231px;
  --container-widget-flex-grow: 0;
  top: -15px;
  z-index: 91;
}

.elementor-26738 .elementor-element.elementor-element-f4295ad img {
  width: 48%;
  border-radius: 100px 100px 100px 100px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-f4295ad {
  left: -228px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-f4295ad {
  right: -228px;
}

.elementor-26738 .elementor-element.elementor-element-2027bd4 {
  text-align: left;
  width: var(--container-widget-width, 50px);
  max-width: 50px;
  --container-widget-width: 50px;
  --container-widget-flex-grow: 0;
  top: 79px;
  z-index: 90;
}

.elementor-26738 .elementor-element.elementor-element-2027bd4 img {
  width: 100%;
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-2027bd4
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-2027bd4 {
  left: 686px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-2027bd4 {
  right: 686px;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f.elementor-position-right
  .elementor-image-box-img {
  margin-left: 12px;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f.elementor-position-left
  .elementor-image-box-img {
  margin-right: 12px;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 12px;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 20%;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f
  .elementor-image-box-wrapper {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f
  .elementor-image-box-title {
  margin-bottom: 0px;
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.2em;
}

.elementor-26738
  .elementor-element.elementor-element-404d18f
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-07564d6
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f709656 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-f709656 img {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e3e3e3;
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-7ec5dae
  > .elementor-container {
  max-width: 660px;
}

.elementor-26738 .elementor-element.elementor-element-7ec5dae {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7ec5dae
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e2bf6ab:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffe2;
}

.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-element-populated {
  border-style: dashed;
  border-width: 1px 1px 1px 1px;
  border-color: #fdb27d;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-e2bf6ab
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-108fb12 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-108fb12
  .elementor-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-108fb12
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 15px 0px 15px 0px;
  border-radius: 10px 10px 10px 10px;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  .uc_clean_tabs
  ul
  li
  a {
  padding: 7px 15px 7px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  .uc_clean_tabs
  ul.tabs
  li
  a {
  width: 201px;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  .uc_clean_tabs
  ul
  li {
  margin: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  .uc_style_pabbly_pricing_tabs_elementor_uc_items_attribute_title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.2px;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  .uc_style_pabbly_pricing_tabs_elementor_uc_items_attribute_tab_content {
  font-family: "Avenir Next W01", Sans-serif;
  font-weight: 400;
}

.elementor-26738
  .elementor-element.elementor-element-34db2c1
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-34db2c1 {
  z-index: 99;
}

.elementor-26738
  .elementor-element.elementor-element-289884b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f1dae42 {
  margin-top: -30px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c2805a0
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4128a0e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9733c90
  > .elementor-container {
  max-width: 1136px;
}

.elementor-26738
  .elementor-element.elementor-element-9733c90:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-9733c90
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-9733c90 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -10px;
  margin-bottom: 0px;
  padding: 0px 0px 15px 0px;
  z-index: 99;
}

.elementor-26738
  .elementor-element.elementor-element-9733c90
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e39fcf1:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #222222;
}

.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.999999999999993, 30.00000000000002, 70, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 20px 30px 0px 30px;
}

.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-e39fcf1
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5340bd8
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738 .elementor-element.elementor-element-5340bd8,
.elementor-26738
  .elementor-element.elementor-element-5340bd8
  > .elementor-background-overlay {
  border-radius: 14px 14px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-5340bd8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 15px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5340bd8
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-255990a
  > .elementor-element-populated {
  margin: 0px 00px 0px 00px;
  --e-column-margin-right: 00px;
  --e-column-margin-left: 00px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-d926ab5 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-d926ab5
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d926ab5
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b4b1ecc
  > .elementor-container {
  max-width: 1136px;
}

.elementor-26738
  .elementor-element.elementor-element-b4b1ecc:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-b4b1ecc
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-b4b1ecc {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 170px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b4b1ecc
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-b3b155c:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.999999999999993, 30.00000000000002, 70, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-b3b155c
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-d60c207
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-d60c207:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-d60c207
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-d60c207 {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #e2e8ef;
  box-shadow: 0px 1px 0px 0px #d9e0e9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 30px 28px 30px;
}

.elementor-26738 .elementor-element.elementor-element-d60c207,
.elementor-26738
  .elementor-element.elementor-element-d60c207
  > .elementor-background-overlay {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d60c207
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-f8d8716
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9d87376 img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-9d87376
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 04px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8baa67d
  > .elementor-element-populated {
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 0px 100px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-748bd6c
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-26738
  .elementor-element.elementor-element-748bd6c
  > .elementor-widget-container {
  padding: 8px 8px 7px 8px;
  background-color: #2687ff;
  border-radius: 5px 5px 5px 5px;
}

.elementor-26738 .elementor-element.elementor-element-748bd6c {
  width: initial;
  max-width: initial;
}

.elementor-26738
  .elementor-element.elementor-element-786a0f0
  .elementor-heading-title {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-786a0f0
  > .elementor-widget-container {
  padding: 12px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e3bfbbd {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-e3bfbbd
  .elementor-heading-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e3bfbbd
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1015ab1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f8702c1
  .elementor-icon-list-icon
  i {
  color: #23b377;
}

.elementor-26738
  .elementor-element.elementor-element-f8702c1
  .elementor-icon-list-icon
  svg {
  fill: #23b377;
}

.elementor-26738 .elementor-element.elementor-element-f8702c1 {
  --e-icon-list-icon-size: 24px;
}

.elementor-26738
  .elementor-element.elementor-element-f8702c1
  .elementor-icon-list-text {
  color: #222222;
  padding-left: 2px;
}

.elementor-26738
  .elementor-element.elementor-element-f8702c1
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-f8702c1
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-26738 .elementor-element.elementor-element-b842e2a {
  text-align: right;
}

.elementor-26738
  .elementor-element.elementor-element-b842e2a
  .elementor-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.elementor-26738
  .elementor-element.elementor-element-b842e2a
  > .elementor-widget-container {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-6841c65
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-6841c65:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-6841c65
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-6841c65 {
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: #e2e8ef;
  box-shadow: 0px 1px 0px 0px #d9e0e9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 28px 30px 28px 30px;
}

.elementor-26738 .elementor-element.elementor-element-6841c65,
.elementor-26738
  .elementor-element.elementor-element-6841c65
  > .elementor-background-overlay {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6841c65
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-1d3eb79
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2b9a12b img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-2b9a12b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 04px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9c8fc15
  > .elementor-element-populated {
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 0px 100px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7d6f479
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-26738
  .elementor-element.elementor-element-7d6f479
  > .elementor-widget-container {
  padding: 8px 8px 7px 8px;
  background-color: #2687ff;
  border-radius: 5px 5px 5px 5px;
}

.elementor-26738 .elementor-element.elementor-element-7d6f479 {
  width: initial;
  max-width: initial;
}

.elementor-26738
  .elementor-element.elementor-element-ed44e5a
  .elementor-heading-title {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-ed44e5a
  > .elementor-widget-container {
  padding: 12px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2dedfb1 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-2dedfb1
  .elementor-heading-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2dedfb1
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1c3549b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e7a6eda
  .elementor-icon-list-icon
  i {
  color: #23b377;
}

.elementor-26738
  .elementor-element.elementor-element-e7a6eda
  .elementor-icon-list-icon
  svg {
  fill: #23b377;
}

.elementor-26738 .elementor-element.elementor-element-e7a6eda {
  --e-icon-list-icon-size: 24px;
}

.elementor-26738
  .elementor-element.elementor-element-e7a6eda
  .elementor-icon-list-text {
  color: #222222;
  padding-left: 2px;
}

.elementor-26738
  .elementor-element.elementor-element-e7a6eda
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-e7a6eda
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-26738 .elementor-element.elementor-element-30304d9 {
  text-align: right;
}

.elementor-26738
  .elementor-element.elementor-element-30304d9
  .elementor-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.elementor-26738
  .elementor-element.elementor-element-30304d9
  > .elementor-widget-container {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-a1732ee
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-a1732ee:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-a1732ee
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-a1732ee {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e2e8ef;
  box-shadow: 0px 1px 0px 0px #d9e0e9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 28px 30px 28px 30px;
}

.elementor-26738 .elementor-element.elementor-element-a1732ee,
.elementor-26738
  .elementor-element.elementor-element-a1732ee
  > .elementor-background-overlay {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a1732ee
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5f48c39
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3dde598 img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-3dde598
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 04px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6375940
  > .elementor-element-populated {
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 0px 100px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0e2016a
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-26738
  .elementor-element.elementor-element-0e2016a
  > .elementor-widget-container {
  padding: 8px 8px 7px 8px;
  background-color: #2687ff;
  border-radius: 5px 5px 5px 5px;
}

.elementor-26738 .elementor-element.elementor-element-0e2016a {
  width: initial;
  max-width: initial;
}

.elementor-26738
  .elementor-element.elementor-element-e8f0ff0
  .elementor-heading-title {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-e8f0ff0
  > .elementor-widget-container {
  padding: 12px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-db8a047 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-db8a047
  .elementor-heading-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-db8a047
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8545496
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6f1aaa6
  .elementor-icon-list-icon
  i {
  color: #23b377;
}

.elementor-26738
  .elementor-element.elementor-element-6f1aaa6
  .elementor-icon-list-icon
  svg {
  fill: #23b377;
}

.elementor-26738 .elementor-element.elementor-element-6f1aaa6 {
  --e-icon-list-icon-size: 24px;
}

.elementor-26738
  .elementor-element.elementor-element-6f1aaa6
  .elementor-icon-list-text {
  color: #222222;
  padding-left: 2px;
}

.elementor-26738
  .elementor-element.elementor-element-6f1aaa6
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-6f1aaa6
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-26738 .elementor-element.elementor-element-f86eee2 {
  text-align: right;
}

.elementor-26738
  .elementor-element.elementor-element-f86eee2
  .elementor-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.elementor-26738
  .elementor-element.elementor-element-f86eee2
  > .elementor-widget-container {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-f862d2d
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-f862d2d:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-f862d2d
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-f862d2d {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e2e8ef;
  box-shadow: 0px 1px 0px 0px #d9e0e9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 28px 30px 28px 30px;
}

.elementor-26738 .elementor-element.elementor-element-f862d2d,
.elementor-26738
  .elementor-element.elementor-element-f862d2d
  > .elementor-background-overlay {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f862d2d
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-84d8730
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8d4f29e img {
  width: 100%;
}

.elementor-26738
  .elementor-element.elementor-element-8d4f29e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 04px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-00ea021
  > .elementor-element-populated {
  margin: 0px 0px 0px 20px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 20px;
  padding: 0px 100px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-62d58fd
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.elementor-26738
  .elementor-element.elementor-element-62d58fd
  > .elementor-widget-container {
  padding: 08px 8px 7px 8px;
  background-color: #2687ff;
  border-radius: 5px 5px 5px 5px;
}

.elementor-26738 .elementor-element.elementor-element-62d58fd {
  width: initial;
  max-width: initial;
}

.elementor-26738
  .elementor-element.elementor-element-e2fa010
  .elementor-heading-title {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-e2fa010
  > .elementor-widget-container {
  padding: 12px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7585ade {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-7585ade
  .elementor-heading-title {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7585ade
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-8c8c1e3
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-fb5b080
  .elementor-icon-list-icon
  i {
  color: #23b377;
}

.elementor-26738
  .elementor-element.elementor-element-fb5b080
  .elementor-icon-list-icon
  svg {
  fill: #23b377;
}

.elementor-26738 .elementor-element.elementor-element-fb5b080 {
  --e-icon-list-icon-size: 24px;
}

.elementor-26738
  .elementor-element.elementor-element-fb5b080
  .elementor-icon-list-text {
  color: #222222;
  padding-left: 2px;
}

.elementor-26738
  .elementor-element.elementor-element-fb5b080
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-fb5b080
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-26738 .elementor-element.elementor-element-c4e98b6 {
  text-align: right;
}

.elementor-26738
  .elementor-element.elementor-element-c4e98b6
  .elementor-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
}

.elementor-26738
  .elementor-element.elementor-element-c4e98b6
  > .elementor-widget-container {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-46059e6
  > .elementor-container {
  max-width: 1300px;
}

.elementor-26738
  .elementor-element.elementor-element-46059e6:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-46059e6
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #edf9ff;
}

.elementor-26738 .elementor-element.elementor-element-46059e6 {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #e2e8ef;
  box-shadow: 0px 1px 0px 0px #d9e0e9;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 15px 0px 15px 0px;
}

.elementor-26738 .elementor-element.elementor-element-46059e6,
.elementor-26738
  .elementor-element.elementor-element-46059e6
  > .elementor-background-overlay {
  border-radius: 0px 0px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-46059e6
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-a787be1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-c98965b {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-c98965b
  .elementor-heading-title {
  color: #222222;
  font-size: 26px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-c98965b
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a63acc8
  > .elementor-container {
  max-width: 1140px;
}

.elementor-26738
  .elementor-element.elementor-element-a63acc8:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-a63acc8
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-a63acc8 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 50px 0px;
  z-index: 99;
}

.elementor-26738
  .elementor-element.elementor-element-a63acc8
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-755a039:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.6679392993472688, 29.788790458312523, 69.75000000000004, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -140px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-755a039
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-db30b41 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 40px 25px 40px;
}

.elementor-26738
  .elementor-element.elementor-element-db30b41
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-0c210f4
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-04af7ba {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-05c5321
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 30px;
}

.elementor-26738 .elementor-element.elementor-element-703dc4e {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-703dc4e
  .elementor-heading-title {
  color: #6d6d6d;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-703dc4e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 5px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-97d97dc {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-97d97dc
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.6em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-97d97dc
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-0917810 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-0917810
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-0917810
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7ce8807 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-7ce8807 img {
  width: 22%;
  opacity: 0.8;
}

.elementor-26738 .elementor-element.elementor-element-3d6e049 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-3d6e049
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8em;
}

.elementor-26738
  .elementor-element.elementor-element-3d6e049
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c78ae62:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-c78ae62
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-c78ae62 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c78ae62
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-ee3eb54 {
  text-align: center;
}

.elementor-26738 .elementor-element.elementor-element-ee3eb54 img {
  width: 22%;
}

.elementor-26738
  .elementor-element.elementor-element-ee3eb54
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d80dcba
  > .elementor-container {
  max-width: 1000px;
}

.elementor-26738
  .elementor-element.elementor-element-d80dcba:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-d80dcba
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-d80dcba
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-d80dcba {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 80px 0px 30px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-274d0cb
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-b5563e9 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-5418a38
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-13498de {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-13498de
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-13498de
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-97f0b5a {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-97f0b5a
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-97f0b5a
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 15px 80px 0px 80px;
}

.elementor-26738
  .elementor-element.elementor-element-234c6b3
  > .elementor-container {
  max-width: 820px;
}

.elementor-26738
  .elementor-element.elementor-element-234c6b3:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-234c6b3
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738 .elementor-element.elementor-element-234c6b3 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 60px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-234c6b3
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-0d32d79:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f9fbff;
}

.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #dee1e6;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 34px 24px 34px;
}

.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-0d32d79
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-07ef3c5 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-4259ee9
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-6f6bd85 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-6f6bd85
  .elementor-heading-title {
  color: #040125;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-6f6bd85
  > .elementor-widget-container {
  padding: 14px 0px 14px 0px;
  background-color: #f6f8fc;
  border-style: dashed;
  border-width: 1px 1px 1px 1px;
  border-color: #bbbfc5;
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738 .elementor-element.elementor-element-75b0e2b {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-276aa47
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6b3c97a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 16px;
}

.elementor-26738
  .elementor-element.elementor-element-065e3fe
  .elementor-heading-title {
  color: #7b7b7b;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-78ef182
  .elementor-heading-title {
  color: #ff4a01;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: bold;
}

.elementor-26738
  .elementor-element.elementor-element-78ef182
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-icon
  i {
  color: #141a3d;
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-icon
  svg {
  fill: #141a3d;
}

.elementor-26738 .elementor-element.elementor-element-788b424 {
  --e-icon-list-icon-size: 14px;
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-text {
  color: #5f5f5f;
  padding-left: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-788b424
  .elementor-icon-list-item
  > a {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.elementor-26738
  .elementor-element.elementor-element-44316c5:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-element-populated {
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-color: #1877f2;
  box-shadow: 0px 10px 50px 0px
    rgba(0, 7.078670236338719, 39.750000000000014, 0.23);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 34px 24px 034px;
}

.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-background-slideshow {
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738
  .elementor-element.elementor-element-44316c5
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-5d8de21 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-235d7a7
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a94999a {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-a94999a
  .elementor-heading-title {
  color: #040125;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.elementor-26738
  .elementor-element.elementor-element-a94999a
  > .elementor-widget-container {
  padding: 14px 0px 14px 0px;
  background-color: #fff664;
  border-style: dashed;
  border-width: 1px 1px 1px 1px;
  border-color: #d7b600;
  border-radius: 8px 8px 8px 8px;
}

.elementor-26738 .elementor-element.elementor-element-1bef2d2 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 30px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1ac7cb5
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-56195a3
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 16px;
}

.elementor-26738
  .elementor-element.elementor-element-6f2b46d
  .elementor-heading-title {
  color: #4b4b4b;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-b6b4d91
  .elementor-heading-title {
  color: #23b377;
  font-family: "Poppins", Sans-serif;
  font-size: 34px;
  font-weight: bold;
}

.elementor-26738
  .elementor-element.elementor-element-b6b4d91
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-icon
  i {
  color: #29c488;
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-icon
  svg {
  fill: #29c488;
}

.elementor-26738 .elementor-element.elementor-element-95804c5 {
  --e-icon-list-icon-size: 14px;
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-text {
  color: #040125;
  padding-left: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-95804c5
  .elementor-icon-list-item
  > a {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.elementor-26738 .elementor-element.elementor-element-5b467b8 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-51712a4
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-6f689b2 img {
  width: 22%;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-6f689b2 {
  left: -201.969px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-6f689b2 {
  right: -201.969px;
}

.elementor-26738 .elementor-element.elementor-element-6f689b2 {
  top: -264.969px;
}

.elementor-26738 .elementor-element.elementor-element-f3b47b7 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9242f8f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-40437c3 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d15913e
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-80f5742 {
  text-align: right;
  width: var(--container-widget-width, 1000px);
  max-width: 1000px;
  --container-widget-width: 1000px;
  --container-widget-flex-grow: 0;
  top: -545px;
}

body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-80f5742 {
  right: -205.391px;
}

body.rtl .elementor-26738 .elementor-element.elementor-element-80f5742 {
  left: -205.391px;
}

.elementor-26738
  .elementor-element.elementor-element-f513a4d
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-f513a4d:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-f513a4d
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-f513a4d
  > .elementor-background-overlay {
  opacity: 0.1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-f513a4d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 170px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-b1ce00f
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-1db4f68
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-1db4f68
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-ab6f6fd {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-ab6f6fd
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-ab6f6fd
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-ab6f6fd
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-ab6f6fd
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2e5f4fd
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-2e5f4fd:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-2e5f4fd
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-2e5f4fd {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 80px 0px;
  z-index: 11;
}

.elementor-26738
  .elementor-element.elementor-element-2e5f4fd
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-07d12dc:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-element-populated
  > .elementor-background-overlay {
  opacity: 0.5;
}

.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-element-populated {
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-color: #ffffff;
  box-shadow: 0px 20px 40px 0px
    rgba(1.6679392993472688, 29.788790458312523, 69.75000000000004, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: -80px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-background-slideshow {
  border-radius: 14px 14px 14px 14px;
}

.elementor-26738
  .elementor-element.elementor-element-07d12dc
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-4ab0aec {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 40px 20px 40px;
}

.elementor-26738
  .elementor-element.elementor-element-4ab0aec
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-e35bdb5
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2925a90 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-2925a90 img {
  border-radius: 100px 100px 100px 100px;
}

.elementor-26738
  .elementor-element.elementor-element-abbccec
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 26px;
}

.elementor-26738 .elementor-element.elementor-element-4ef5079 {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-4ef5079 img {
  width: 12%;
  opacity: 1;
  filter: brightness(100%) contrast(108%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.elementor-26738
  .elementor-element.elementor-element-4ef5079
  > .elementor-widget-container {
  margin: -8px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-a6bb588 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-a6bb588
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
}

.elementor-26738
  .elementor-element.elementor-element-a6bb588
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-f39a463 {
  text-align: left;
}

.elementor-26738
  .elementor-element.elementor-element-f39a463
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-26738
  .elementor-element.elementor-element-f39a463
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f0969cd
  > .elementor-container {
  max-width: 1920px;
}

.elementor-26738
  .elementor-element.elementor-element-f0969cd:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-f0969cd
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f5f9fb;
}

.elementor-26738 .elementor-element.elementor-element-f0969cd {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 100px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-f0969cd
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-5c9c9cd
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c73dead
  > .elementor-container {
  max-width: 874px;
}

.elementor-26738 .elementor-element.elementor-element-c73dead {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9e056e0
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-c771753 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-c771753
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c771753
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-09c57ea {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-09c57ea
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-09c57ea
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-d334663
  > .elementor-container {
  max-width: 1920px;
}

.elementor-26738 .elementor-element.elementor-element-d334663 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-61014f7
  > .elementor-element-populated {
  margin: 0px 15px 0px 0px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-366b3ff
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-87b7cad img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-87b7cad
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8effc70 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-8effc70
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-4d801c8 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-4d801c8
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2fb8d5d img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-2fb8d5d
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-fcecc93 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-fcecc93
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-98146e9 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-98146e9
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-17144ee img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-17144ee
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-764bd3f img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-764bd3f
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-e4ef910 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-e4ef910
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-477855a img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-477855a
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-574a23e img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-574a23e
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-8112412 img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-8112412
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-2f2139f img {
  width: 94%;
}

.elementor-26738
  .elementor-element.elementor-element-2f2139f
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-c3ba2ed
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-67ebbd2 {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-67ebbd2
  .elementor-icon-list-text {
  color: #54595f;
}

.elementor-26738
  .elementor-element.elementor-element-67ebbd2
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-67ebbd2
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-67ebbd2
  > .elementor-widget-container {
  margin: -6px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3d0a73ed
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-3d0a73ed
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-3d0a73ed:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-3d0a73ed
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff 40%, #f6fcff 100%);
}

.elementor-26738 .elementor-element.elementor-element-3d0a73ed,
.elementor-26738
  .elementor-element.elementor-element-3d0a73ed
  > .elementor-background-overlay {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3d0a73ed {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 90px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3d0a73ed
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-dcb7274
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-64057045
  > .elementor-widget-container {
  margin: 0px 0px 40px 0px;
}

.elementor-26738 .elementor-element.elementor-element-7440f13c {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-7440f13c
  .elementor-heading-title {
  color: #030025;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7440f13c
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-12942e23
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-12942e23
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
}

.elementor-26738 .elementor-element.elementor-element-12942e23 {
  padding: 20px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2597c0d9
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-2597c0d9
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-2597c0d9
  > .elementor-background-slideshow {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2597c0d9
  > .elementor-element-populated {
  margin: 0px 8px 0px 0px;
  --e-column-margin-right: 8px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title {
  border-width: 0px;
  padding: 20px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-icon {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-30c8f201:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-e29b2a4:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-bd75885:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-423ab80:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-48729cb:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-9f6b2f3:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-169f640:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-941435e:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-3b1efb48
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-3b1efb48
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-3b1efb48
  > .elementor-background-slideshow {
  border-radius: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3b1efb48
  > .elementor-element-populated {
  margin: 0px 0px 0px 8px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 8px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title {
  border-width: 0px;
  padding: 20px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 0px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-icon {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-331328a8:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-6c2c7afb:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-title {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-3ba6f4c:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-3d4ff38:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-fb3c791:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-3f778343:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-16641576:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title {
  border-width: 0px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-content {
  border-width: 0px;
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  padding: 15px 20px 0px 20px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-item:not(:last-child) {
  margin-bottom: 20px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-title,
.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-icon {
  color: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-icon
  svg {
  fill: #030025;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title.elementor-active
  a,
.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon {
  color: #111f62;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-title {
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title
  .elementor-toggle-icon
  i:before {
  color: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title
  .elementor-toggle-icon
  svg {
  fill: #1d1b21;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  i:before {
  color: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-tab-title.elementor-active
  .elementor-toggle-icon
  svg {
  fill: #a8a8a8;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-icon.elementor-toggle-icon-left {
  margin-right: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  .elementor-toggle-icon.elementor-toggle-icon-right {
  margin-left: 4px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 14px 0px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: #dfe9ff;
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-67fc4a5:hover
  .elementor-widget-container {
  box-shadow: 0px 6px 15px 0px rgba(2, 35, 132, 0.14);
}

.elementor-26738
  .elementor-element.elementor-element-043c835
  > .elementor-container {
  max-width: 1140px;
}

.elementor-26738
  .elementor-element.elementor-element-043c835
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-26738
  .elementor-element.elementor-element-043c835:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-043c835
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-043c835
  > .elementor-background-overlay {
  background-color: rgba(244, 244, 244, 0);
  opacity: 0.4;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-043c835 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 80px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-96db733
  > .elementor-element-populated {
  text-align: left;
}

.elementor-26738 .elementor-element.elementor-element-2382436 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-2382436
  .elementor-heading-title {
  color: #040125;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.3em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-2382436
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-3131490 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-3131490
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3131490
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 200px 0px 200px;
}

.elementor-26738
  .elementor-element.elementor-element-340cfc4
  > .elementor-container
  > .elementor-column
  > .elementor-widget-wrap {
  align-content: center;
  align-items: center;
}

.elementor-26738 .elementor-element.elementor-element-340cfc4 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
  z-index: 9;
}

.elementor-26738
  .elementor-element.elementor-element-a250814:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: rgba(132, 132, 132, 0);
}

.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-element-populated,
.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-background-slideshow {
  border-radius: 6px 6px 6px 6px;
}

.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 10px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-a250814
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-a250814 {
  z-index: 0;
}

.elementor-26738 .elementor-element.elementor-element-d070ae8 img {
  border-style: solid;
  border-width: 4px 4px 4px 4px;
  border-color: #ffffff;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0px 10px 25px 0px
    rgba(0, 20.755434782608695, 50.24999999999999, 0.17);
}

.elementor-26738
  .elementor-element.elementor-element-d070ae8
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-73341d6b
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738
  .elementor-element.elementor-element-73341d6b:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-73341d6b
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f6fcff;
}

.elementor-26738
  .elementor-element.elementor-element-73341d6b
  > .elementor-background-overlay {
  opacity: 0.8;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738 .elementor-element.elementor-element-73341d6b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: -140px;
  margin-bottom: 0px;
  padding: 200px 0px 55px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-752cf9bb
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 15px 0px 15px;
}

.elementor-26738
  .elementor-element.elementor-element-5cb173e1
  > .elementor-container {
  max-width: 1100px;
}

.elementor-26738 .elementor-element.elementor-element-5cb173e1 {
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-7493a290
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-6dc22749 {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-6dc22749
  .elementor-heading-title {
  color: #222222;
  font-family: "Poppins", Sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-6dc22749
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-9e9abde {
  text-align: center;
}

.elementor-26738
  .elementor-element.elementor-element-9e9abde
  .elementor-heading-title {
  color: #1d1b21;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.9em;
  letter-spacing: 0.3px;
}

.elementor-26738
  .elementor-element.elementor-element-9e9abde
  > .elementor-widget-container {
  margin: -12px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button
  .elementor-align-icon-right {
  margin-left: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button
  .elementor-align-icon-left {
  margin-right: 10px;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  background-color: #006df9;
  border-radius: 9px 9px 9px 9px;
  padding: 16px 24px 16px 24px;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button:hover,
.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button:focus {
  color: #ffffff;
  background-color: #005ed8;
  border-color: #007bff;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button:hover
  svg,
.elementor-26738
  .elementor-element.elementor-element-891e713
  .elementor-button:focus
  svg {
  fill: #ffffff;
}

.elementor-26738
  .elementor-element.elementor-element-891e713
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738 .elementor-element.elementor-element-cf668de {
  --e-icon-list-icon-size: 13px;
}

.elementor-26738
  .elementor-element.elementor-element-cf668de
  .elementor-icon-list-text {
  color: #040125;
  padding-left: 0px;
}

.elementor-26738
  .elementor-element.elementor-element-cf668de
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-26738
  .elementor-element.elementor-element-cf668de
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.elementor-26738
  .elementor-element.elementor-element-cf668de
  > .elementor-widget-container {
  margin: -5px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3b24211:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-26738
  .elementor-element.elementor-element-3b24211
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #f6fcff;
}

.elementor-26738 .elementor-element.elementor-element-3b24211 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-26738
  .elementor-element.elementor-element-3b24211
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-26738
  .elementor-element.elementor-element-ab85fd2
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

@media (min-width: 768px) {
  .elementor-26738 .elementor-element.elementor-element-0b1e6b6 {
    width: 14%;
  }
  .elementor-26738 .elementor-element.elementor-element-2defe65 {
    width: 71.333%;
  }
  .elementor-26738 .elementor-element.elementor-element-bf61c55 {
    width: 14%;
  }
  .elementor-26738 .elementor-element.elementor-element-af2367d {
    width: 12%;
  }
  .elementor-26738 .elementor-element.elementor-element-5855bf8 {
    width: 53%;
  }
  .elementor-26738 .elementor-element.elementor-element-3c75c63 {
    width: 34.555%;
  }
  .elementor-26738 .elementor-element.elementor-element-cfc8d0d {
    width: 11.491%;
  }
  .elementor-26738 .elementor-element.elementor-element-9b680ed {
    width: 54.211%;
  }
  .elementor-26738 .elementor-element.elementor-element-bd71a63 {
    width: 34.294%;
  }
  .elementor-26738 .elementor-element.elementor-element-02fe9f1 {
    width: 37%;
  }
  .elementor-26738 .elementor-element.elementor-element-831d71c {
    width: 15%;
  }
  .elementor-26738 .elementor-element.elementor-element-19230ba {
    width: 15%;
  }
  .elementor-26738 .elementor-element.elementor-element-9011f1b {
    width: 13%;
  }
  .elementor-26738 .elementor-element.elementor-element-347ae8f {
    width: 40%;
  }
  .elementor-26738 .elementor-element.elementor-element-8c6a64f {
    width: 60%;
  }
  .elementor-26738 .elementor-element.elementor-element-3d73bad {
    width: 32.242%;
  }
  .elementor-26738 .elementor-element.elementor-element-c06e75c {
    width: 32%;
  }
  .elementor-26738 .elementor-element.elementor-element-d7e0dff {
    width: 35.091%;
  }
  .elementor-26738 .elementor-element.elementor-element-86130c4 {
    width: 48%;
  }
  .elementor-26738 .elementor-element.elementor-element-8af982b {
    width: 51.836%;
  }
  .elementor-26738 .elementor-element.elementor-element-5942926 {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-cef26d5 {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-24a9315 {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-4eb3f6f {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-19cce5e {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-d7d0737 {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-754a815 {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-482effb {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-d52666f {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-3ec4c29 {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-e3b161f {
    width: 18%;
  }
  .elementor-26738 .elementor-element.elementor-element-f6640d7 {
    width: 81.998%;
  }
  .elementor-26738 .elementor-element.elementor-element-d47248c {
    width: 40%;
  }
  .elementor-26738 .elementor-element.elementor-element-3a5c694 {
    width: 60%;
  }
  .elementor-26738 .elementor-element.elementor-element-8708740 {
    width: 37%;
  }
  .elementor-26738 .elementor-element.elementor-element-dd6ad26 {
    width: 28%;
  }
  .elementor-26738 .elementor-element.elementor-element-b050374 {
    width: 34.333%;
  }
  .elementor-26738 .elementor-element.elementor-element-c7f9b98 {
    width: 42%;
  }
  .elementor-26738 .elementor-element.elementor-element-02aef26 {
    width: 57.68%;
  }
  .elementor-26738 .elementor-element.elementor-element-ac4428a {
    width: 12%;
  }
  .elementor-26738 .elementor-element.elementor-element-73dd5e5 {
    width: 88%;
  }
  .elementor-26738 .elementor-element.elementor-element-b92ffa9 {
    width: 12%;
  }
  .elementor-26738 .elementor-element.elementor-element-f1b3e03 {
    width: 88%;
  }
  .elementor-26738 .elementor-element.elementor-element-871ab2a {
    width: 50.3%;
  }
  .elementor-26738 .elementor-element.elementor-element-49808eb {
    width: 49.7%;
  }
  .elementor-26738 .elementor-element.elementor-element-00bbf72 {
    width: 11%;
  }
  .elementor-26738 .elementor-element.elementor-element-5de5af1 {
    width: 89%;
  }
  .elementor-26738 .elementor-element.elementor-element-4c28066 {
    width: 33.53%;
  }
  .elementor-26738 .elementor-element.elementor-element-b4a92a2 {
    width: 66.468%;
  }
  .elementor-26738 .elementor-element.elementor-element-f02a05e {
    width: 12%;
  }
  .elementor-26738 .elementor-element.elementor-element-ff8fb1b {
    width: 88%;
  }
  .elementor-26738 .elementor-element.elementor-element-c9a3970 {
    width: 91%;
  }
  .elementor-26738 .elementor-element.elementor-element-07564d6 {
    width: 9%;
  }
  .elementor-26738 .elementor-element.elementor-element-f8d8716 {
    width: 4%;
  }
  .elementor-26738 .elementor-element.elementor-element-8baa67d {
    width: 79.5%;
  }
  .elementor-26738 .elementor-element.elementor-element-1015ab1 {
    width: 16.164%;
  }
  .elementor-26738 .elementor-element.elementor-element-1d3eb79 {
    width: 4%;
  }
  .elementor-26738 .elementor-element.elementor-element-9c8fc15 {
    width: 79.5%;
  }
  .elementor-26738 .elementor-element.elementor-element-1c3549b {
    width: 16.164%;
  }
  .elementor-26738 .elementor-element.elementor-element-5f48c39 {
    width: 4%;
  }
  .elementor-26738 .elementor-element.elementor-element-6375940 {
    width: 79.5%;
  }
  .elementor-26738 .elementor-element.elementor-element-8545496 {
    width: 16.164%;
  }
  .elementor-26738 .elementor-element.elementor-element-84d8730 {
    width: 4%;
  }
  .elementor-26738 .elementor-element.elementor-element-00ea021 {
    width: 79.5%;
  }
  .elementor-26738 .elementor-element.elementor-element-8c8c1e3 {
    width: 16.164%;
  }
  .elementor-26738 .elementor-element.elementor-element-0c210f4 {
    width: 11%;
  }
  .elementor-26738 .elementor-element.elementor-element-05c5321 {
    width: 89%;
  }
  .elementor-26738 .elementor-element.elementor-element-276aa47 {
    width: 15%;
  }
  .elementor-26738 .elementor-element.elementor-element-6b3c97a {
    width: 85%;
  }
  .elementor-26738 .elementor-element.elementor-element-1ac7cb5 {
    width: 15%;
  }
  .elementor-26738 .elementor-element.elementor-element-56195a3 {
    width: 85%;
  }
  .elementor-26738 .elementor-element.elementor-element-e35bdb5 {
    width: 12%;
  }
  .elementor-26738 .elementor-element.elementor-element-abbccec {
    width: 88%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-26738 .elementor-element.elementor-element-0b1e6b6 {
    width: 45%;
  }
  .elementor-26738 .elementor-element.elementor-element-2defe65 {
    width: 45%;
  }
  .elementor-26738 .elementor-element.elementor-element-bf61c55 {
    width: 26%;
  }
  .elementor-26738 .elementor-element.elementor-element-cfc8d0d {
    width: 20%;
  }
  .elementor-26738 .elementor-element.elementor-element-9b680ed {
    width: 40%;
  }
  .elementor-26738 .elementor-element.elementor-element-bd71a63 {
    width: 40%;
  }
  .elementor-26738 .elementor-element.elementor-element-eb39e06 {
    width: 20%;
  }
  .elementor-26738 .elementor-element.elementor-element-02fe9f1 {
    width: 16%;
  }
  .elementor-26738 .elementor-element.elementor-element-19230ba {
    width: 26%;
  }
  .elementor-26738 .elementor-element.elementor-element-9011f1b {
    width: 23%;
  }
  .elementor-26738 .elementor-element.elementor-element-347ae8f {
    width: 22%;
  }
  .elementor-26738 .elementor-element.elementor-element-8c6a64f {
    width: 78%;
  }
  .elementor-26738 .elementor-element.elementor-element-9a801fa {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-5c9c563 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-c7f9b98 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-02aef26 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-88d5a9a {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-d5e0b09 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-e229ad7 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-f4cbf5f {
    width: 33.3%;
  }
  .elementor-26738 .elementor-element.elementor-element-3637700 {
    width: 33.3%;
  }
  .elementor-26738 .elementor-element.elementor-element-6cc04d7 {
    width: 33.3%;
  }
  .elementor-26738 .elementor-element.elementor-element-d5b10a9 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-4c28066 {
    width: 55%;
  }
  .elementor-26738 .elementor-element.elementor-element-b4a92a2 {
    width: 26%;
  }
  .elementor-26738 .elementor-element.elementor-element-3a9882d {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-255990a {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-f8d8716 {
    width: 8%;
  }
  .elementor-26738 .elementor-element.elementor-element-8baa67d {
    width: 67%;
  }
  .elementor-26738 .elementor-element.elementor-element-1015ab1 {
    width: 25%;
  }
  .elementor-26738 .elementor-element.elementor-element-1d3eb79 {
    width: 8%;
  }
  .elementor-26738 .elementor-element.elementor-element-9c8fc15 {
    width: 67%;
  }
  .elementor-26738 .elementor-element.elementor-element-1c3549b {
    width: 25%;
  }
  .elementor-26738 .elementor-element.elementor-element-5f48c39 {
    width: 8%;
  }
  .elementor-26738 .elementor-element.elementor-element-6375940 {
    width: 67%;
  }
  .elementor-26738 .elementor-element.elementor-element-8545496 {
    width: 25%;
  }
  .elementor-26738 .elementor-element.elementor-element-84d8730 {
    width: 8%;
  }
  .elementor-26738 .elementor-element.elementor-element-00ea021 {
    width: 67%;
  }
  .elementor-26738 .elementor-element.elementor-element-8c8c1e3 {
    width: 25%;
  }
  .elementor-26738 .elementor-element.elementor-element-a787be1 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-5418a38 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-9e056e0 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-61014f7 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-a250814 {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .elementor-26738
    .elementor-element.elementor-element-338234b
    > .elementor-background-overlay {
    background-attachment: scroll;
  }
  #elementor-lightbox-dd4c6f6 .elementor-video-container {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .elementor-26738 .elementor-element.elementor-element-b31ca4e {
    padding: 15px 20px 32px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-wrapper {
    max-width: 100%;
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-item:not(:first-of-type) {
    margin-left: calc(4px / 2);
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-item:not(:last-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-item:not(:first-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-item:not(:last-of-type) {
    margin-left: calc(4px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-digits {
    font-size: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3a7dcc3
    .elementor-heading-title {
    font-size: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3a7dcc3
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 5px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8158af
    .elementor-button {
    font-size: 15px;
    padding: 6px 10px 6px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-338234b {
    margin-top: -2px;
    margin-bottom: 0px;
    padding: 15px 8px 10px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f77cfbd
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a32d839
    .elementor-heading-title {
    font-size: 18px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-a32d839
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-wrapper {
    max-width: 46%;
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-item:not(:first-of-type) {
    margin-left: calc(4px / 2);
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-item:not(:last-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-item:not(:first-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-item:not(:last-of-type) {
    margin-left: calc(4px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-digits {
    font-size: 18px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a14bd0
    .elementor-heading-title {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a14bd0
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3c75c63
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-aa5d89b
    .elementor-heading-title {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-aa5d89b
    > .elementor-widget-container {
    margin: -2px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4984b5b
    .elementor-button {
    font-size: 16px;
    padding: 6px 10px 6px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4984b5b
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4abbbf8 {
    padding: 20px 20px 20px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu
    .elementor-item {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--main
    .elementor-item {
    padding-left: 14px;
    padding-right: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-306c622 {
    --e-nav-menu-horizontal-menu-item-margin: calc(0px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
    .elementor-nav-menu
    > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-175c4f6 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-02fe9f1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-19230ba
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu
    .elementor-item {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--main
    .elementor-item {
    padding-left: 0px;
    padding-right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-537e2a0 {
    --e-nav-menu-horizontal-menu-item-margin: calc(40px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
    .elementor-nav-menu
    > li:not(:last-child) {
    margin-bottom: 40px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 10px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9011f1b
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-14051fa
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9078116 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 30px 0px 20px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-76d5b43
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-cf92829 {
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c32f327
    > .elementor-widget-container {
    margin: 4px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu
    .elementor-item {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--main
    .elementor-item {
    padding-left: 14px;
    padding-right: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-4feb5c0 {
    --e-nav-menu-horizontal-menu-item-margin: calc(0px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
    .elementor-nav-menu
    > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    > .elementor-widget-container {
    padding: 5px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-14eb141 img {
    width: 34%;
  }
  .elementor-26738
    .elementor-element.elementor-element-304f9e3
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-304f9e3
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738 .elementor-element.elementor-element-d8d0625 img {
    width: 34%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f203b7d
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f203b7d
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738 .elementor-element.elementor-element-4338e8c img {
    width: 34%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f1bff9
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f1bff9
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738 .elementor-element.elementor-element-b90ca14 {
    padding: 0px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1dcdc80
    .elementor-heading-title {
    font-size: 40px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1dcdc80
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdcdc35
    > .elementor-widget-container {
    padding: 0px 0px 0px 00px;
  }
  .elementor-26738 .elementor-element.elementor-element-e1e6750 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dd4c6f6
    > .elementor-widget-container {
    margin: -40px 20px 0px 20px;
    padding: 0px 0px 0px 0px;
    border-width: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7381530 {
    padding: 20px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8af982b
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-113f509 {
    padding: 0px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9a801fa
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5ff78a6
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-568ed8d {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bf7f3ea
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 6px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5daba1f
    > .elementor-element-populated {
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9af7d2a
    > .elementor-element-populated {
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ec99240
    > .elementor-element-populated {
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-7fe2712 {
    padding: 80px 30px 40px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-4fc5ada img {
    width: 29%;
  }
  .elementor-26738 .elementor-element.elementor-element-0005387 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 60px 30px 60px 30px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dad665d
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-f48bd4d {
    --e-image-carousel-slides-to-show: 4;
  }
  .elementor-26738 .elementor-element.elementor-element-8a49bf8 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 40px 30px 60px 30px;
  }
  .elementor-26738
    .elementor-element.elementor-element-32c0dc5
    .elementor-heading-title {
    font-size: 30px;
    line-height: 1.6em;
  }
  .elementor-26738
    .elementor-element.elementor-element-32c0dc5
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-003e4d4
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f4f2f58
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2
    .elementor-main-swiper {
    height: 218px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2.elementor-skin-slideshow
    .elementor-main-swiper {
    margin-bottom: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2
    > .elementor-widget-container {
    margin: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f530c26
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-cae6dca {
    padding: 0px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0bf3e71
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0bf3e71
    > .elementor-widget-container {
    padding: 60px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8d34453
    > .elementor-widget-container {
    margin: -6px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-38578b7 {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c651dee
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d961901
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4d019e9
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0431c15
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f151bce
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b087e86
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-230d715 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e7dced
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-29cf370 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 60px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-28869e2
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac85ed5
    .elementor-heading-title {
    font-size: 35px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac85ed5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f1a2eee
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5b83898 {
    padding: 60px 30px 60px 30px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4280ec0
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4280ec0
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4d5717
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dee14df
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5b39d92 {
    padding: 15px 10px 15px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8708740
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1ade038 img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f76182d
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f76182d
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dd6ad26
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3d67e02 img {
    width: 46%;
  }
  .elementor-26738
    .elementor-element.elementor-element-2930ef9
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2930ef9
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b050374
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8b5edc2 img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdf8658
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdf8658
    > .elementor-widget-container {
    padding: 0px 40px 0px 40px;
  }
  .elementor-26738 .elementor-element.elementor-element-0247815 {
    padding: 0px 20px 60px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-abd07b7
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-02aef26
    > .elementor-element-populated {
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-30d307f {
    padding: 60px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-938646a
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7e16edb
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7e16edb
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3516e1
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3ccda14 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 30px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ae4eace
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3f0794
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b24e7cf
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8d22d4e
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d9ba0e
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f00f72
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-c5e2059 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8f91fba
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0df538e
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-141e689
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-87fe43d
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0ce7aae
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ea2a7fa
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-7ab63b5 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3c5e770
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f16fead
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bea19cf
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f790560
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0cb19f1
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d26bfdd
    > .elementor-element-populated {
    margin: 0px 16px 0px 0px;
    --e-column-margin-right: 16px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-8a12b68 {
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9ec5c4
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-aeaf2d5 {
    padding: 60px 20px 50px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd568cc
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd568cc
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-446fa26
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-74f0e5f img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-77dfe68 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-d3b06fa img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-4be8fb4
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-91da731
    > .elementor-element-populated {
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6b26914 {
    padding: 30px 30px 30px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-f9ab24f img {
    width: 16%;
  }
  .elementor-26738
    .elementor-element.elementor-element-1bd1532
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1bd1532
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f196242
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-ef1fc78 {
    padding: 20px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-25e010b
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-25e010b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0850417
    > .elementor-widget-container {
    margin: -8px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f3e5e9b {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-00086bf
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-78df82e
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-18588ec {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ce69bda
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1857916
    > .elementor-element-populated {
    margin: 0px 12px 0px 0px;
    --e-column-margin-right: 12px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-a039127 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f0a04fa
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c3bd2c3
    > .elementor-element-populated {
    margin: 0px 12px 0px 0px;
    --e-column-margin-right: 12px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-5e39cc7 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9a289ac
    > .elementor-element-populated {
    margin: 0px 12px 0px 0px;
    --e-column-margin-right: 12px;
    --e-column-margin-left: 0px;
    padding: 30px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d99c0b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2939bfa {
    padding: 60px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-80430d8
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-80430d8
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f6ec3ff
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0cdca29 {
    padding: 0px 0px 060px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-210439b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3edf49a {
    padding: 60px 20px 60px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f9d252
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f4cbf5f
    > .elementor-element-populated {
    margin: 0px 10px 0px 0px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 0px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7f1138d
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c9847a
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c9847a
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3637700
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a63876
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-98ef103
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-98ef103
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6cc04d7
    > .elementor-element-populated {
    margin: 0px 0px 0px 10px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 10px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-645e8c0
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-cd9c514
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-cd9c514
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-531afff {
    padding: 010px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ca710a9
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-da1596d
    > .elementor-element-populated {
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2b6826a {
    padding: 30px 30px 30px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-0b7e195 img {
    width: 16%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac0480a
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac0480a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0366634
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-d766607 {
    padding: 0px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2891a92
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2891a92
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1570401
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-282a056 {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0b4fb11
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-57b7ae0 {
    padding: 60px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-253087c
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-253087c
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6678b7a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8aecde4 {
    padding: 40px 0px 120px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-eb164cf
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-78cd18d img {
    width: 80%;
  }
  .elementor-26738
    .elementor-element.elementor-element-78cd18d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 06px 0px 0px 6px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b8713ca
    > .elementor-element-populated {
    margin: -52px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ba4404e {
    padding: 30px 30px 10px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-6a0c028 img {
    width: 16%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f14f82c
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f14f82c
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d21c089
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-b70e9c7 {
    padding: 40px 10px 00px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-66454e6 {
    padding: 30px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9d104d
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9d104d
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e870c5b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-561afc3 {
    padding: 0px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c9a3970
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f4295ad img {
    width: 41%;
  }
  .elementor-26738 .elementor-element.elementor-element-f4295ad {
    width: var(--container-widget-width, 231px);
    max-width: 231px;
    --container-widget-width: 231px;
    --container-widget-flex-grow: 0;
    top: 31px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-f4295ad {
    left: -29px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-f4295ad {
    right: -29px;
  }
  .elementor-26738 .elementor-element.elementor-element-2027bd4 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-2027bd4 {
    width: var(--container-widget-width, 49px);
    max-width: 49px;
    --container-widget-width: 49px;
    --container-widget-flex-grow: 0;
    top: 99px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-2027bd4 {
    left: 590px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-2027bd4 {
    right: 590px;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f
    .elementor-image-box-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-7ec5dae {
    padding: 0px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2bf6ab
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-34db2c1
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9733c90 {
    padding: 0px 15px 00px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e39fcf1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5340bd8 {
    padding: 15px 25px 15px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-255990a
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d926ab5 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-d926ab5
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d926ab5
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b4b1ecc {
    padding: 20px 15px 100px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b3b155c
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d60c207 {
    padding: 25px 25px 25px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8d8716
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8baa67d
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-748bd6c
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-748bd6c
    > .elementor-widget-container {
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-786a0f0
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-786a0f0
    > .elementor-widget-container {
    padding: 12px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1015ab1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b842e2a
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b842e2a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6841c65 {
    padding: 25px 25px 25px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1d3eb79
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9c8fc15
    > .elementor-element-populated {
    margin: 0px 0px 0px 15px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 15px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d6f479
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d6f479
    > .elementor-widget-container {
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ed44e5a
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ed44e5a
    > .elementor-widget-container {
    padding: 12px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1c3549b
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-30304d9
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-30304d9
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a1732ee {
    padding: 25px 25px 25px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f48c39
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6375940
    > .elementor-element-populated {
    margin: 0px 0px 0px 15px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 15px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e2016a
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e2016a
    > .elementor-widget-container {
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e8f0ff0
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e8f0ff0
    > .elementor-widget-container {
    padding: 12px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8545496
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f1aaa6
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f86eee2
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f86eee2
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f862d2d {
    padding: 25px 25px 25px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-84d8730
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-62d58fd
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-62d58fd
    > .elementor-widget-container {
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2fa010
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2fa010
    > .elementor-widget-container {
    padding: 12px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8c8c1e3
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4e98b6
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4e98b6
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-46059e6 {
    padding: 15px 25px 15px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c98965b
    .elementor-heading-title {
    font-size: 28px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c98965b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-755a039
    > .elementor-element-populated {
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-db30b41 {
    padding: 30px 30px 30px 30px;
  }
  .elementor-26738
    .elementor-element.elementor-element-05c5321
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 30px;
  }
  .elementor-26738
    .elementor-element.elementor-element-703dc4e
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-26738
    .elementor-element.elementor-element-703dc4e
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d6e049
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-d80dcba {
    padding: 80px 20px 30px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-13498de
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-13498de
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-97f0b5a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-234c6b3 {
    padding: 0px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0d32d79
    > .elementor-element-populated {
    padding: 26px 26px 26px 26px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f6bd85
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-44316c5
    > .elementor-element-populated {
    padding: 26px 26px 26px 26px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a94999a
    .elementor-heading-title {
    font-size: 20px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    left: -192px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    right: -192px;
  }
  .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    top: -268px;
  }
  .elementor-26738 .elementor-element.elementor-element-f513a4d {
    padding: 30px 20px 80px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1db4f68
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-07d12dc
    > .elementor-element-populated {
    margin: 0px 20px 0px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4ab0aec {
    padding: 30px 30px 15px 30px;
  }
  .elementor-26738 .elementor-element.elementor-element-4ef5079 img {
    width: 16%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a6bb588
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a6bb588
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f39a463
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-f0969cd {
    padding: 0px 0px 100px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9e056e0
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c771753
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c771753
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d334663 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-61014f7
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-366b3ff img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-366b3ff
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c3ba2ed
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3d0a73ed {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 20px 60px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7440f13c
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7440f13c
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-12942e23 {
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-30c8f201
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-331328a8
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    .elementor-toggle-title {
    line-height: 1.5em;
  }
  .elementor-26738 .elementor-element.elementor-element-043c835 {
    padding: 50px 25px 0px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2382436
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3131490
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-340cfc4 {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a250814
    > .elementor-element-populated {
    margin: -75px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 90px 25px 0px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d070ae8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-73341d6b {
    margin-top: -140px;
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6dc22749
    .elementor-heading-title {
    font-size: 35px;
  }
  .elementor-26738
    .elementor-element.elementor-element-891e713
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media (max-width: 767px) {
  .elementor-26738 .elementor-element.elementor-element-b31ca4e {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 10px 20px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-1e27d81 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0b1e6b6
    > .elementor-element-populated {
    margin: -4px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-wrapper {
    max-width: 52%;
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-digits {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    .elementor-countdown-label {
    font-size: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-080fd63
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2defe65
    > .elementor-element-populated {
    margin: -4px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3a7dcc3 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-3a7dcc3
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3a7dcc3
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 12px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bf61c55
    > .elementor-element-populated {
    margin: 0px 0px -10px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8158af
    .elementor-button {
    padding: 4px 15px 4px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8158af
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 4px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-338234b {
    margin-top: -2px;
    margin-bottom: 0px;
    padding: 12px 10px 12px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-af2367d {
    width: 28%;
  }
  .elementor-26738
    .elementor-element.elementor-element-af2367d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f77cfbd
    > .elementor-widget-container {
    margin: -12px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5855bf8 {
    width: 72%;
  }
  .elementor-26738 .elementor-element.elementor-element-a32d839 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-a32d839
    .elementor-heading-title {
    font-size: 21px;
    line-height: 1.2em;
  }
  .elementor-26738
    .elementor-element.elementor-element-a32d839
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-wrapper {
    max-width: 84%;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-item {
    padding: 10px 8px 0px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-digits {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    .elementor-countdown-label {
    font-size: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6d294d2
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4a14bd0 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a14bd0
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.6em;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a14bd0
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-aa5d89b {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-aa5d89b
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.6em;
  }
  .elementor-26738
    .elementor-element.elementor-element-aa5d89b
    > .elementor-widget-container {
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4984b5b
    .elementor-button {
    font-size: 16px;
    padding: 5px 50px 5px 50px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4984b5b
    > .elementor-widget-container {
    margin: -12px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4abbbf8 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 6px 7px 12px 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-cfc8d0d {
    width: 46%;
  }
  .elementor-26738
    .elementor-element.elementor-element-cfc8d0d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3c4df57 {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-3c4df57 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3c4df57
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9b680ed {
    width: 16%;
  }
  .elementor-26738
    .elementor-element.elementor-element-9b680ed
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-bd71a63 {
    width: 38%;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd71a63
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-framed
    .elementor-item:before {
    border-width: 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-framed.e--animation-draw
    .elementor-item:before {
    border-width: 0 0 25px 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-framed.e--animation-draw
    .elementor-item:after {
    border-width: 25px 25px 0 0;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-framed.e--animation-corners
    .elementor-item:before {
    border-width: 25px 0 0 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-framed.e--animation-corners
    .elementor-item:after {
    border-width: 0 25px 25px 0;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-underline
    .elementor-item:after,
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-overline
    .elementor-item:before,
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-double-line
    .elementor-item:before,
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .e--pointer-double-line
    .elementor-item:after {
    height: 25px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--main
    .elementor-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown
    .elementor-item,
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown
    .elementor-sub-item {
    font-size: 17px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown {
    border-radius: 0px 0px 12px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown
    li:first-child
    a {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown
    li:last-child
    a {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--dropdown
    a {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu--main
    > .elementor-nav-menu
    > li
    > .elementor-nav-menu--dropdown,
  .elementor-26738
    .elementor-element.elementor-element-306c622
    .elementor-nav-menu__container.elementor-nav-menu--dropdown {
    margin-top: 25px !important;
  }
  .elementor-26738
    .elementor-element.elementor-element-306c622
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-175c4f6 {
    padding: 20px 20px 20px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-eb39e06 {
    width: 44%;
  }
  .elementor-26738
    .elementor-element.elementor-element-eb39e06
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-62532f3 img {
    width: 99%;
  }
  .elementor-26738 .elementor-element.elementor-element-02fe9f1 {
    width: 2%;
  }
  .elementor-26738 .elementor-element.elementor-element-19230ba {
    width: 6%;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--dropdown {
    border-radius: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--dropdown
    li:first-child
    a {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--dropdown
    li:last-child
    a {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--dropdown
    a {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu--main
    > .elementor-nav-menu
    > li
    > .elementor-nav-menu--dropdown,
  .elementor-26738
    .elementor-element.elementor-element-537e2a0
    .elementor-nav-menu__container.elementor-nav-menu--dropdown {
    margin-top: 20px !important;
  }
  .elementor-26738 .elementor-element.elementor-element-9011f1b {
    width: 40%;
  }
  .elementor-26738
    .elementor-element.elementor-element-14051fa
    .elementor-button {
    font-size: 16px;
    padding: 10px 20px 10px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-9078116 {
    padding: 0px 0px 50px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-76d5b43
    > .elementor-element-populated {
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-cf92829 {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-347ae8f {
    width: 60%;
  }
  .elementor-26738
    .elementor-element.elementor-element-347ae8f
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c32f327 {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-c32f327 img {
    width: 100%;
    max-width: 79%;
  }
  .elementor-26738
    .elementor-element.elementor-element-c32f327
    > .elementor-widget-container {
    margin: 6px 0px 0px 16px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8c6a64f {
    width: 40%;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu
    .elementor-item {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--main
    .elementor-item {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4feb5c0 {
    --e-nav-menu-horizontal-menu-item-margin: calc(0px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--main:not(.elementor-nav-menu--layout-horizontal)
    .elementor-nav-menu
    > li:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown
    .elementor-item,
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown
    .elementor-sub-item {
    font-size: 17px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown {
    border-radius: 0px 0px 10px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown
    li:first-child
    a {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown
    li:last-child
    a {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--dropdown
    a {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu--main
    > .elementor-nav-menu
    > li
    > .elementor-nav-menu--dropdown,
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    .elementor-nav-menu__container.elementor-nav-menu--dropdown {
    margin-top: 20px !important;
  }
  .elementor-26738
    .elementor-element.elementor-element-4feb5c0
    > .elementor-widget-container {
    margin: 0px 6px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-304f9e3 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-304f9e3
    .elementor-heading-title {
    font-size: 27px;
  }
  .elementor-26738
    .elementor-element.elementor-element-304f9e3
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f203b7d {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-f203b7d
    .elementor-heading-title {
    font-size: 27px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f203b7d
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d7e0dff
    > .elementor-element-populated {
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-4338e8c {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-5f1bff9 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f1bff9
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f1bff9
    > .elementor-widget-container {
    margin: -8px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b90ca14 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6a41fd1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1dcdc80 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-1dcdc80
    .elementor-heading-title {
    font-size: 25px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-1dcdc80
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-fdcdc35 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdcdc35
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdcdc35
    > .elementor-widget-container {
    margin: -12px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e1e6750 {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9597439
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dd4c6f6
    > .elementor-widget-container {
    margin: -40px 20px 0px 20px;
    border-width: 1px 1px 1px 1px;
    border-radius: 6px 6px 6px 6px;
  }
  .elementor-26738 .elementor-element.elementor-element-7381530 {
    padding: 20px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-86130c4
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5095bf4
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-26738
    .elementor-element.elementor-element-5095bf4
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8af982b
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4fb8592
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-26738
    .elementor-element.elementor-element-4fb8592
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4fb8592
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-113f509 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9a801fa
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1bb866e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5ff78a6
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5ff78a6
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-568ed8d {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 15px 20px 0px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8a8bf59
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 4px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3b89bab {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5daba1f
    > .elementor-element-populated {
    margin: 0px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-5942926 {
    width: 16%;
  }
  .elementor-26738 .elementor-element.elementor-element-cef26d5 {
    width: 84%;
  }
  .elementor-26738
    .elementor-element.elementor-element-019a05e
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9af7d2a
    > .elementor-element-populated {
    margin: 20px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-24a9315 {
    width: 16%;
  }
  .elementor-26738 .elementor-element.elementor-element-4eb3f6f {
    width: 84%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a5926ff
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ec99240
    > .elementor-element-populated {
    margin: 20px 15px 0px 15px;
    --e-column-margin-right: 15px;
    --e-column-margin-left: 15px;
    padding: 15px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-19cce5e {
    width: 16%;
  }
  .elementor-26738 .elementor-element.elementor-element-d7d0737 {
    width: 84%;
  }
  .elementor-26738
    .elementor-element.elementor-element-b825203
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-923a252 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7ef98ad {
    width: 33.3%;
  }
  .elementor-26738
    .elementor-element.elementor-element-7ef98ad
    > .elementor-element-populated {
    margin: 0px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-754a815 {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-754a815
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e2e07a6 img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2e07a6
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-482effb {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-482effb
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9dcfa66 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-9dcfa66
    .elementor-heading-title {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9dcfa66
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3b7f7fd {
    width: 33.3%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3b7f7fd
    > .elementor-element-populated {
    margin: 0px 3px 0px 3px;
    --e-column-margin-right: 3px;
    --e-column-margin-left: 3px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-d52666f {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-a39a31c img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a39a31c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3ec4c29 {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ec4c29
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-70b867d {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-70b867d
    .elementor-heading-title {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-70b867d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5715a03 {
    width: 33.3%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5715a03
    > .elementor-element-populated {
    margin: 0px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-e3b161f {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-ae15960 img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ae15960
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f6640d7 {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f6640d7
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5a24440 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-5a24440
    .elementor-heading-title {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5a24440
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7fe2712 {
    margin-top: 40px;
    margin-bottom: 0px;
    padding: 40px 20px 20px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-383cea8 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-383cea8
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-383cea8
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4ef924a {
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-d47248c {
    width: 24%;
  }
  .elementor-26738 .elementor-element.elementor-element-4fc5ada img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-3a5c694 {
    width: 70%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3a5c694
    > .elementor-element-populated {
    margin: 10px 0px 0px 20px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-3f9cb49 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f9cb49
    .elementor-heading-title {
    font-size: 13px;
    line-height: 1.5em;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f9cb49
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0005387 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-31dfaaa
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 20px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-dad665d {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-dad665d
    .elementor-heading-title {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dad665d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0d4189c {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-90aa1e8
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f48bd4d {
    --e-image-carousel-slides-to-show: 2;
  }
  .elementor-26738
    .elementor-element.elementor-element-f48bd4d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8a49bf8 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 10px 25px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-96030b3
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 20px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-32c0dc5 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-32c0dc5
    .elementor-heading-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-32c0dc5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-003e4d4 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-003e4d4
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-003e4d4
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f4f2f58 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-f4f2f58
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f4f2f58
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    border-radius: 20px 20px 20px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2
    .elementor-main-swiper {
    height: 224px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2.elementor-skin-slideshow
    .elementor-main-swiper {
    margin-bottom: 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4828e2
    > .elementor-widget-container {
    margin: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f530c26
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f530c26
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-cae6dca {
    padding: 25px 0px 35px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-35452ed
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0bf3e71 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-0bf3e71
    .elementor-heading-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0bf3e71
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-8d34453 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-8d34453
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-8d34453
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-38578b7 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c651dee
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7d6f1b0 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-f146989 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-f146989
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-27ae495 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-27ae495
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d961901
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e753f43 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-26ebfe3 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-26ebfe3
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-224937c {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-224937c
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4d019e9
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-94ca9df img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-5804030 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-5804030
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-db0c762 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-db0c762
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738 .elementor-element.elementor-element-705e3fe {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0431c15
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-db3ec95 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-db3ec95
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-db3ec95
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-c838822 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-c838822
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-c838822
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-4aeb0ab img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-4aeb0ab
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f151bce
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a9274c2 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-a9274c2
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a9274c2
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-d83f130 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-d83f130
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-d83f130
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-5de7ca5 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5de7ca5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b087e86
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d26a5c7 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-d26a5c7
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d26a5c7
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-5cf0e07 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-5cf0e07
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-5cf0e07
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-d54c1cb img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-d54c1cb
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-230d715 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9b8424d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e7dced
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e7dced
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-29cf370 {
    padding: 35px 0px 35px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-28869e2
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ac85ed5 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac85ed5
    .elementor-heading-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac85ed5
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-f1a2eee {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-f1a2eee
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f1a2eee
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-df463b3
    > .elementor-widget-container {
    padding: 0px 5px 0px 5px;
  }
  .elementor-26738 .elementor-element.elementor-element-5b83898 {
    padding: 35px 5px 35px 5px;
  }
  .elementor-26738 .elementor-element.elementor-element-4280ec0 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-4280ec0
    .elementor-heading-title {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4280ec0
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-e4d5717 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4d5717
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.4em;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4d5717
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d1d390e
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-17881a6 {
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dee14df
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5b39d92 {
    padding: 15px 15px 10px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8708740
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1ade038 {
    text-align: center;
  }
  .elementor-26738 .elementor-element.elementor-element-f76182d {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-f76182d
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f76182d
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2930ef9 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-2930ef9
    .elementor-heading-title {
    font-size: 27px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2930ef9
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-fdf8658 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdf8658
    .elementor-heading-title {
    font-size: 27px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fdf8658
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0247815 {
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5c9c563
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5d14000 {
    padding: 20px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c7f9b98
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5283679
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5283679
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-15de290
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-15de290
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-15de290
    .elementor-image-box-description {
    font-size: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-74d6086
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-74d6086
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-74d6086
    .elementor-image-box-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-74d6086
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-807ef7c
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-807ef7c
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-807ef7c
    .elementor-image-box-description {
    font-size: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4f96c2
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4f96c2
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4f96c2
    .elementor-image-box-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4f96c2
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-980badf
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-980badf
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-980badf
    .elementor-image-box-description {
    font-size: 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7082c61
    .elementor-image-box-img {
    margin-bottom: 4px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7082c61
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-7082c61
    .elementor-image-box-title {
    font-size: 16px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7082c61
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-abd07b7
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-abd07b7
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-02aef26
    > .elementor-element-populated {
    margin: 30px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-81b1146
    > .elementor-widget-container {
    margin: 0px -15px 0px -15px;
  }
  .elementor-26738 .elementor-element.elementor-element-30d307f {
    padding: 30px 15px 40px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-938646a
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7e16edb {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-7e16edb
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7e16edb
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e3516e1 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3516e1
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3516e1
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3ccda14 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ae4eace {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ae4eace
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-686143f img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-22ef69c
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-22ef69c
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-66635c5
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-66635c5 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-66635c5 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-66635c5 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e3f0794 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3f0794
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-fa712cf img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5c234a8
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5c234a8
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9707166
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-9707166 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-9707166 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9707166 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b24e7cf {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-b24e7cf
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-ae08d71 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5a952e8
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-5a952e8
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a202f2a
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-a202f2a {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-a202f2a {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a202f2a {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8d22d4e {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8d22d4e
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-d8f2d3e img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-83f757a
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-83f757a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-df67e30
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-df67e30 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-df67e30 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-df67e30 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2d9ba0e {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d9ba0e
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-2e2619b img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8e29811
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8e29811
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7567c3a
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-7567c3a {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-7567c3a {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7567c3a {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6f00f72 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f00f72
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-784b4ec img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-97ecb10
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-97ecb10
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f18a6e
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-9f18a6e {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-9f18a6e {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9f18a6e {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c5e2059 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8f91fba {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8f91fba
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-e6f6cd5 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-4b2fa64
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4b2fa64
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ede6ee3
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-ede6ee3 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-ede6ee3 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ede6ee3 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0df538e {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0df538e
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-3a75b3b img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f813b19
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f813b19
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a6a7e8c
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-a6a7e8c {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-a6a7e8c {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a6a7e8c {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-141e689 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-141e689
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-b16b000 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8a0dcb
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8a0dcb
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-945105b
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-945105b {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-945105b {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-945105b {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-87fe43d {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-87fe43d
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-26eeb06 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-2a98a91
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2a98a91
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-519d2cb
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-519d2cb {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-519d2cb {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-519d2cb {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0ce7aae {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0ce7aae
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-5868060 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f3c4574
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f3c4574
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-cd289ac
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-cd289ac {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-cd289ac {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-cd289ac {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ea2a7fa {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ea2a7fa
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-daae3df img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-13dcb24
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-13dcb24
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c2161e1
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-c2161e1 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-c2161e1 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c2161e1 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7ab63b5 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3c5e770 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3c5e770
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-cac5591 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-1a635dd
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1a635dd
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-823e1ca
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-823e1ca {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-823e1ca {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-823e1ca {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f16fead {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f16fead
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-9a6b9dd img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-b9b56da
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b9b56da
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7bc89cb
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-7bc89cb {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-7bc89cb {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7bc89cb {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-bea19cf {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-bea19cf
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-d77c142 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e79d451
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e79d451
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1299724
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-1299724 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-1299724 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1299724 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f790560 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f790560
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-9c9582f img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d654a2
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d654a2
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-faa1a03
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-faa1a03 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-faa1a03 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-faa1a03 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0cb19f1 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0cb19f1
    > .elementor-element-populated {
    margin: 12px 6px 0px 0px;
    --e-column-margin-right: 6px;
    --e-column-margin-left: 0px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-150d13c img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4c201b
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4c201b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f823865
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-f823865 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-f823865 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f823865 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d26bfdd {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-d26bfdd
    > .elementor-element-populated {
    margin: 12px 0px 0px 6px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 6px;
    padding: 20px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-350aae3 img {
    width: 26%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8ed986d
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8ed986d
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-acf46a2
    .elementor-heading-title {
    font-size: 10px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-acf46a2 {
    left: 0px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-acf46a2 {
    right: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-acf46a2 {
    top: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8a12b68 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-47c3efa
    > .elementor-element-populated {
    margin: 10px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9ec5c4
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9ec5c4
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-aeaf2d5 {
    padding: 40px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7386036
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-bd568cc {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd568cc
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd568cc
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-446fa26 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-446fa26
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-446fa26
    > .elementor-widget-container {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-74f0e5f img {
    width: 100%;
    max-width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-74f0e5f
    > .elementor-widget-container {
    margin: -10px -5px 0px -5px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-77dfe68 img {
    width: 100%;
    max-width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-77dfe68
    > .elementor-widget-container {
    margin: -10px -5px 0px -5px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d3b06fa img {
    width: 100%;
    max-width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-d3b06fa
    > .elementor-widget-container {
    margin: -10px -5px 0px -5px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1999313 img {
    width: 100%;
    max-width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-1999313
    > .elementor-widget-container {
    margin: -10px -5px 0px -5px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4be8fb4
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4be8fb4
    > .elementor-widget-container {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-5a9f96b {
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-91da731
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6b26914 {
    padding: 25px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-ac4428a {
    width: 22%;
  }
  .elementor-26738 .elementor-element.elementor-element-73dd5e5 {
    width: 78%;
  }
  .elementor-26738
    .elementor-element.elementor-element-73dd5e5
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-f9ab24f img {
    width: 46%;
  }
  .elementor-26738 .elementor-element.elementor-element-1bd1532 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-1bd1532
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-1bd1532
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f196242
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-ef1fc78 {
    padding: 20px 15px 10px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9725ae0
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-25e010b {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-25e010b
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-25e010b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0850417 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-0850417
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0850417
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f3e5e9b {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-00086bf
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f84c9f
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-78df82e
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-88998b6
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-18588ec {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ce69bda
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-63a9716
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1857916
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-8512f3f
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a039127 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f0a04fa
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0f185c5
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c3bd2c3
    > .elementor-element-populated {
    border-width: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 0%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    .elementor-image-box-wrapper {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    .elementor-image-box-title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    .elementor-image-box-description {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-a74fde9
    > .elementor-widget-container {
    margin: -20px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5e39cc7 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9a289ac
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 30px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d99c0b
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d99c0b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2939bfa {
    padding: 30px 15px 20px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-80430d8 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-80430d8
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-80430d8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f6ec3ff {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-f6ec3ff
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f6ec3ff
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 8px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-0cdca29 {
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2c08028
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d15e8de
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-210439b
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-210439b
    > .elementor-widget-container {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-3edf49a {
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d5e0b09
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8999f8e {
    padding: 50px 10px 0px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e229ad7
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4c579e4 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c579e4
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c579e4
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 5px 0px 5px;
  }
  .elementor-26738 .elementor-element.elementor-element-3f9d252 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f9d252
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f9d252
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 5px 0px 5px;
  }
  .elementor-26738 .elementor-element.elementor-element-33d1b88 {
    padding: 20px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f4cbf5f
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 15px 15px 20px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-7f1138d {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-7f1138d
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-7f1138d
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4c9847a {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c9847a
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-4c9847a
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3637700
    > .elementor-element-populated {
    margin: 30px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 15px 15px 20px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-4a63876 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a63876
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-4a63876
    > .elementor-widget-container {
    margin: -05px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-98ef103 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-98ef103
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-98ef103
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6cc04d7
    > .elementor-element-populated {
    margin: 30px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 15px 15px 25px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-645e8c0 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-645e8c0
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-645e8c0
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-cd9c514 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-cd9c514
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-cd9c514
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-531afff {
    padding: 20px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d5b10a9
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ca710a9
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-ca710a9
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a3a4550 {
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-da1596d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2b6826a {
    padding: 25px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-b92ffa9 {
    width: 22%;
  }
  .elementor-26738 .elementor-element.elementor-element-f1b3e03 {
    width: 78%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f1b3e03
    > .elementor-element-populated {
    padding: 0px 0px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-0b7e195 img {
    width: 46%;
  }
  .elementor-26738 .elementor-element.elementor-element-ac0480a {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac0480a
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-ac0480a
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0366634
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-d766607 {
    padding: 20px 15px 40px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-8ee051f
    > .elementor-element-populated {
    padding: 0px 00px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2891a92 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-2891a92
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2891a92
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1570401 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-1570401
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-1570401
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-df5a44b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-282a056 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-adc5a17
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0b4fb11
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0b4fb11
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-57b7ae0 {
    padding: 50px 20px 0px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-253087c {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-253087c
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-253087c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6678b7a {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-6678b7a
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-6678b7a
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8aecde4 {
    padding: 30px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-871ab2a
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9381eb3 {
    text-align: center;
  }
  .elementor-26738 .elementor-element.elementor-element-9381eb3 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-9381eb3
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-49808eb
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 15px 28px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-db14a00 {
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-00bbf72 {
    width: 16%;
  }
  .elementor-26738 .elementor-element.elementor-element-d2a6c01 {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-d2a6c01 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-5de5af1 {
    width: 84%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5de5af1
    > .elementor-element-populated {
    padding: 0px 0px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-bae8786 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-bae8786
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-77c83d2 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-77c83d2
    .elementor-heading-title {
    font-size: 26px;
    line-height: 0em;
  }
  .elementor-26738
    .elementor-element.elementor-element-77c83d2
    > .elementor-widget-container {
    margin: -11px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8de69c8 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-8de69c8
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.6em;
  }
  .elementor-26738
    .elementor-element.elementor-element-8de69c8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(3px / 2);
    margin-left: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-3px / 2);
    margin-left: calc(-3px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    left: calc(-3px / 2);
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    right: calc(-3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a7f14cb
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-dca485e {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4c28066 {
    width: 55%;
  }
  .elementor-26738
    .elementor-element.elementor-element-eb164cf
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-eb164cf
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b4a92a2 {
    width: 40%;
  }
  .elementor-26738
    .elementor-element.elementor-element-b4a92a2
    > .elementor-element-populated {
    padding: 0px 0px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-78cd18d {
    text-align: center;
  }
  .elementor-26738 .elementor-element.elementor-element-78cd18d img {
    width: 68%;
  }
  .elementor-26738
    .elementor-element.elementor-element-78cd18d
    > .elementor-widget-container {
    margin: -4px 0px 0px 0px;
    padding: 0px 0px 0px 8px;
  }
  .elementor-26738 .elementor-element.elementor-element-72239b9 {
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b8713ca
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-ba4404e {
    padding: 25px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-f02a05e {
    width: 22%;
  }
  .elementor-26738 .elementor-element.elementor-element-ff8fb1b {
    width: 78%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ff8fb1b
    > .elementor-element-populated {
    padding: 0px 0px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-6a0c028 img {
    width: 46%;
  }
  .elementor-26738 .elementor-element.elementor-element-f14f82c {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-f14f82c
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f14f82c
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d21c089
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-b70e9c7 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 5px 0px 5px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2d471c9
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d58ac1a
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3a9882d {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-e9d104d {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9d104d
    .elementor-heading-title {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e9d104d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-e870c5b {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e870c5b
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e870c5b
    > .elementor-widget-container {
    padding: 0px 10px 0px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-561afc3 {
    padding: 0px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-c9a3970 {
    width: 76%;
  }
  .elementor-26738
    .elementor-element.elementor-element-c9a3970
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f4295ad img {
    width: 36%;
  }
  .elementor-26738 .elementor-element.elementor-element-2027bd4 img {
    width: 36%;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f.elementor-position-right
    .elementor-image-box-img {
    margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f.elementor-position-left
    .elementor-image-box-img {
    margin-right: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 44%;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f
    .elementor-image-box-title {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-404d18f
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-07564d6 {
    width: 24%;
  }
  .elementor-26738 .elementor-element.elementor-element-f709656 {
    text-align: right;
  }
  .elementor-26738 .elementor-element.elementor-element-f709656 img {
    width: 84%;
  }
  .elementor-26738 .elementor-element.elementor-element-7ec5dae {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e2bf6ab {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2bf6ab
    > .elementor-element-populated {
    margin: 0px 10px 0px 10px;
    --e-column-margin-right: 10px;
    --e-column-margin-left: 10px;
    padding: 0px 12px 0px 12px;
  }
  .elementor-26738 .elementor-element.elementor-element-108fb12 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-108fb12
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.6em;
  }
  .elementor-26738
    .elementor-element.elementor-element-108fb12
    > .elementor-widget-container {
    padding: 10px 0px 10px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-34db2c1
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-289884b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f1dae42 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c2805a0
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-4128a0e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9733c90 {
    margin-top: -30px;
    margin-bottom: 0px;
    padding: 0px 15px 20px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e39fcf1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 10px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-5340bd8 {
    padding: 0px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-255990a {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-255990a
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d926ab5 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-d926ab5
    .elementor-heading-title {
    font-size: 18px;
    line-height: 1.3em;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-d926ab5
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b4b1ecc {
    padding: 0px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-b3b155c
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 20px 15px 25px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-d60c207 {
    padding: 20px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f8d8716 {
    width: 13%;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8d8716
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9d87376 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-8baa67d {
    width: 85%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8baa67d
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-748bd6c {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-748bd6c
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-748bd6c
    > .elementor-widget-container {
    padding: 4px 12px 4px 12px;
  }
  .elementor-26738 .elementor-element.elementor-element-786a0f0 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-786a0f0
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-786a0f0
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e3bfbbd {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e3bfbbd
    > .elementor-widget-container {
    margin: -14px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1015ab1 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-f8702c1 {
    --e-icon-list-icon-size: 24px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f8702c1
    > .elementor-widget-container {
    margin: 0px 0px 0px -40px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b842e2a {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-b842e2a
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-b842e2a
    > .elementor-widget-container {
    margin: -6px 0px 0px -40px;
    padding: 0px 30px 10px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6841c65 {
    padding: 20px 00px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1d3eb79 {
    width: 13%;
  }
  .elementor-26738
    .elementor-element.elementor-element-1d3eb79
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2b9a12b img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-9c8fc15 {
    width: 85%;
  }
  .elementor-26738
    .elementor-element.elementor-element-9c8fc15
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-7d6f479 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d6f479
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-7d6f479
    > .elementor-widget-container {
    padding: 4px 12px 4px 12px;
  }
  .elementor-26738 .elementor-element.elementor-element-ed44e5a {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-ed44e5a
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-ed44e5a
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2dedfb1 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-2dedfb1
    > .elementor-widget-container {
    margin: -14px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e7a6eda {
    --e-icon-list-icon-size: 24px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e7a6eda
    > .elementor-widget-container {
    margin: 0px 0px 0px -40px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-30304d9 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-30304d9
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-30304d9
    > .elementor-widget-container {
    margin: -6px 0px 0px -40px;
    padding: 0px 30px 10px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a1732ee {
    padding: 20px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5f48c39 {
    width: 13%;
  }
  .elementor-26738
    .elementor-element.elementor-element-5f48c39
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3dde598 img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-6375940 {
    width: 85%;
  }
  .elementor-26738
    .elementor-element.elementor-element-6375940
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-0e2016a {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e2016a
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0e2016a
    > .elementor-widget-container {
    padding: 4px 12px 4px 12px;
  }
  .elementor-26738 .elementor-element.elementor-element-e8f0ff0 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e8f0ff0
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e8f0ff0
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-db8a047 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-db8a047
    > .elementor-widget-container {
    margin: -14px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6f1aaa6 {
    --e-icon-list-icon-size: 24px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f1aaa6
    > .elementor-widget-container {
    margin: 0px 0px 0px -40px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f86eee2 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-f86eee2
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-f86eee2
    > .elementor-widget-container {
    margin: -6px 0px 0px -40px;
    padding: 0px 30px 10px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-f862d2d {
    padding: 20px 0px 20px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-84d8730 {
    width: 13%;
  }
  .elementor-26738
    .elementor-element.elementor-element-84d8730
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8d4f29e img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-00ea021 {
    width: 85%;
  }
  .elementor-26738
    .elementor-element.elementor-element-00ea021
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-62d58fd {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-62d58fd
    .elementor-heading-title {
    font-size: 12px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-62d58fd
    > .elementor-widget-container {
    padding: 4px 12px 4px 12px;
  }
  .elementor-26738 .elementor-element.elementor-element-e2fa010 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2fa010
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e2fa010
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7585ade {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-7585ade
    > .elementor-widget-container {
    margin: -14px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-fb5b080 {
    --e-icon-list-icon-size: 24px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb5b080
    > .elementor-widget-container {
    margin: 0px 0px 0px -40px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c4e98b6 {
    text-align: center;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4e98b6
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-c4e98b6
    > .elementor-widget-container {
    margin: -6px 0px 0px -40px;
    padding: 0px 30px 10px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-46059e6 {
    padding: 15px 10px 15px 10px;
  }
  .elementor-26738 .elementor-element.elementor-element-a787be1 {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a787be1
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c98965b {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-c98965b
    .elementor-heading-title {
    font-size: 16px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-c98965b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a63acc8 {
    padding: 0px 0px 30px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-755a039
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-db30b41 {
    padding: 25px 15px 15px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-0c210f4 {
    width: 22%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0c210f4
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-04af7ba img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-05c5321 {
    width: 78%;
  }
  .elementor-26738
    .elementor-element.elementor-element-05c5321
    > .elementor-element-populated {
    padding: 0px 0px 0px 10px;
  }
  .elementor-26738
    .elementor-element.elementor-element-703dc4e
    .elementor-heading-title {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-703dc4e
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-97d97dc
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-97d97dc
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-0917810
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-0917810
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7ce8807 img {
    width: 74%;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d6e049
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-ee3eb54 img {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-ee3eb54
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-d80dcba {
    padding: 30px 20px 10px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-b5563e9 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5418a38 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-13498de {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-13498de
    .elementor-heading-title {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-13498de
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-97f0b5a {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-97f0b5a
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-97f0b5a
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-234c6b3 {
    padding: 15px 0px 30px 5px;
  }
  .elementor-26738 .elementor-element.elementor-element-0d32d79 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-0d32d79
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f6bd85
    .elementor-heading-title {
    font-size: 17px;
  }
  .elementor-26738 .elementor-element.elementor-element-75b0e2b {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-276aa47 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-9f79019 {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-9f79019 img {
    width: 30%;
  }
  .elementor-26738
    .elementor-element.elementor-element-6b3c97a
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-065e3fe {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-065e3fe
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-78ef182 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-78ef182
    .elementor-heading-title {
    font-size: 20px;
    line-height: 0em;
  }
  .elementor-26738
    .elementor-element.elementor-element-78ef182
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(5px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(5px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(5px / 2);
    margin-left: calc(5px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-5px / 2);
    margin-left: calc(-5px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    left: calc(-5px / 2);
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    right: calc(-5px / 2);
  }
  .elementor-26738 .elementor-element.elementor-element-788b424 {
    --e-icon-list-icon-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-26738
    .elementor-element.elementor-element-788b424
    .elementor-icon-list-item
    > a {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-788b424
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-44316c5 {
    width: 50%;
  }
  .elementor-26738
    .elementor-element.elementor-element-44316c5
    > .elementor-element-populated {
    border-width: 2px 2px 2px 2px;
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 8px 8px 8px 8px;
  }
  .elementor-26738
    .elementor-element.elementor-element-a94999a
    .elementor-heading-title {
    font-size: 17px;
  }
  .elementor-26738 .elementor-element.elementor-element-1bef2d2 {
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-1ac7cb5 {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-5262caa {
    text-align: left;
  }
  .elementor-26738 .elementor-element.elementor-element-5262caa img {
    width: 30%;
  }
  .elementor-26738
    .elementor-element.elementor-element-56195a3
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6f2b46d {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-6f2b46d
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-b6b4d91 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-b6b4d91
    .elementor-heading-title {
    font-size: 20px;
    line-height: 0em;
  }
  .elementor-26738
    .elementor-element.elementor-element-b6b4d91
    > .elementor-widget-container {
    margin: -5px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:last-child) {
    padding-bottom: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items:not(.elementor-inline-items)
    .elementor-icon-list-item:not(:first-child) {
    margin-top: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item {
    margin-right: calc(3px / 2);
    margin-left: calc(3px / 2);
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items.elementor-inline-items {
    margin-right: calc(-3px / 2);
    margin-left: calc(-3px / 2);
  }
  body.rtl
    .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    left: calc(-3px / 2);
  }
  body:not(.rtl)
    .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-items.elementor-inline-items
    .elementor-icon-list-item:after {
    right: calc(-3px / 2);
  }
  .elementor-26738 .elementor-element.elementor-element-95804c5 {
    --e-icon-list-icon-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    .elementor-icon-list-item
    > a {
    font-size: 12px;
  }
  .elementor-26738
    .elementor-element.elementor-element-95804c5
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
  }
  body:not(.rtl) .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    left: 4.938px;
  }
  body.rtl .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    right: 4.938px;
  }
  .elementor-26738 .elementor-element.elementor-element-6f689b2 {
    top: -491.891px;
  }
  .elementor-26738 .elementor-element.elementor-element-f513a4d {
    padding: 10px 20px 40px 20px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1db4f68
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-1db4f68
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2e5f4fd {
    padding: 0px 0px 40px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-07d12dc
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4ab0aec {
    padding: 30px 20px 10px 20px;
  }
  .elementor-26738 .elementor-element.elementor-element-e35bdb5 {
    width: 22%;
  }
  .elementor-26738 .elementor-element.elementor-element-abbccec {
    width: 78%;
  }
  .elementor-26738
    .elementor-element.elementor-element-abbccec
    > .elementor-element-populated {
    margin: 0px 0px 0px 15px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 15px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4ef5079 img {
    width: 46%;
  }
  .elementor-26738 .elementor-element.elementor-element-a6bb588 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-a6bb588
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-a6bb588
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-f39a463
    .elementor-heading-title {
    font-size: 14px;
  }
  .elementor-26738 .elementor-element.elementor-element-f0969cd {
    padding: 20px 0px 40px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c73dead {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-c771753 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-c771753
    .elementor-heading-title {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c771753
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-09c57ea {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-09c57ea
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
  }
  .elementor-26738
    .elementor-element.elementor-element-09c57ea
    > .elementor-widget-container {
    padding: 10px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-d334663 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-61014f7
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-366b3ff img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-87b7cad img {
    width: 100%;
  }
  .elementor-26738 .elementor-element.elementor-element-8effc70 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8effc70
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-4d801c8 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-4d801c8
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2fb8d5d img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-2fb8d5d
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-fcecc93 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-fcecc93
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-98146e9 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-98146e9
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-17144ee img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-17144ee
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-764bd3f img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-764bd3f
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-e4ef910 img {
    width: 81%;
  }
  .elementor-26738
    .elementor-element.elementor-element-e4ef910
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-477855a img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-477855a
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-574a23e img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-574a23e
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-8112412 img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-8112412
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-2f2139f img {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-2f2139f
    > .elementor-widget-container {
    margin: -15px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c3ba2ed
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-c3ba2ed
    > .elementor-widget-container {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-3d0a73ed {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 15px 40px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-dcb7274
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-7440f13c {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-7440f13c
    .elementor-heading-title {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7440f13c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-12942e23 {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2597c0d9
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-30c8f201
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-e29b2a4
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-bd75885
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-423ab80
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-48729cb
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9f6b2f3
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-169f640
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-941435e
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3b1efb48
    > .elementor-element-populated {
    margin: 10px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-331328a8
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-331328a8
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6c2c7afb
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3ba6f4c
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3d4ff38
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-fb3c791
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-3f778343
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-16641576
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    .elementor-toggle-item:not(:last-child) {
    margin-bottom: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    .elementor-toggle-title {
    font-size: 16px;
    line-height: 1.7em;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    .elementor-tab-title {
    padding: 15px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    .elementor-tab-content {
    padding: 0px 15px 0px 15px;
  }
  .elementor-26738
    .elementor-element.elementor-element-67fc4a5
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-043c835 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 40px 15px 0px 15px;
  }
  .elementor-26738 .elementor-element.elementor-element-2382436 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-2382436
    .elementor-heading-title {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-2382436
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-3131490 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-3131490
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.4em;
  }
  .elementor-26738
    .elementor-element.elementor-element-3131490
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-340cfc4 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-a250814 {
    width: 100%;
  }
  .elementor-26738
    .elementor-element.elementor-element-a250814
    > .elementor-element-populated {
    margin: -80px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 80px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-73341d6b {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 30px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-5cb173e1 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-7493a290
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-6dc22749 {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-6dc22749
    .elementor-heading-title {
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-6dc22749
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-26738 .elementor-element.elementor-element-9e9abde {
    text-align: left;
  }
  .elementor-26738
    .elementor-element.elementor-element-9e9abde
    .elementor-heading-title {
    font-size: 15px;
    line-height: 1.5em;
    letter-spacing: 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-9e9abde
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-26738
    .elementor-element.elementor-element-891e713
    .elementor-button {
    letter-spacing: 0px;
    padding: 12px 20px 12px 020px;
  }
  .elementor-26738
    .elementor-element.elementor-element-891e713
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
}

/* Start custom CSS for button, class: .elementor-element-f8158af */

/*Shake Btn Stylings*/

.shake-btn a {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-name: shake-hv;
  animation-timing-function: ease-in-out;
}

@keyframes shake-hv {
  0%,
  80% {
    transform: translate(0, 0) rotate(0);
  }
  60%,
  70% {
    transform: translate(0, -0.5px) rotate(2.5deg);
  }
  62%,
  72% {
    transform: translate(0, 1.5px) rotate(-0.5deg);
  }
  65%,
  75% {
    transform: translate(0, -1.5px) rotate(2.5deg);
  }
  67%,
  77% {
    transform: translate(0, 2.5px) rotate(-1.5deg);
  }
}

.shake-btn svg {
  vertical-align: text-top;
}

/* End custom CSS */

/* Start custom CSS for nav-menu, class: .elementor-element-306c622 */

li.menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children.menu-item-12108
  a#sm-1547728309973098-3 {
  background: #39b54a;
  color: #fff !important;
  border-radius: 4px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 10px;
}

li.menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children.menu-item-12108
  a#sm-1547728309973098-3:hover {
  background: #30943d;
}

.eicon-close {
  position: absolute;
  right: 0px;
  top: -1px;
  width: 32px;
  height: 32px;
  opacity: 0.5;
}

.eicon-close:before,
.eicon-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 22px;
  width: 2px;
  background-color: #333;
}

/* End custom CSS */

/* Start custom CSS for nav-menu, class: .elementor-element-537e2a0 */

/*li.a{
    background: #39b54a;
    color:#fff!important;
    border-radius:4px;
    padding-top: 12px!important;
    padding-bottom: 12px!important;
    margin-top: 10px;
}
li.menu-item.menu-item-type-custom.menu-item-object-custom.menu-item-has-children.menu-item-12108 a#sm-1547728309973098-3:hover {
    background: #30943d;
}*/

.prod-menu1 ul li ul li a {
  display: contents;
  white-space: normal !important;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .prod-menu1 ul li ul {
    width: 720px !important;
    margin-left: -395px !important;
    border: 1px solid #ececec;
  }
}

@media only screen and (min-width: 1024px) {
  .prod-menu1 ul li ul {
    width: 720px !important;
    margin-left: -600px !important;
    border: 1px solid #ececec;
  }
}

/* End custom CSS */

/* Start custom CSS for section, class: .elementor-element-175c4f6 */

.elementor-invisible {
  visibility: visible;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-5095bf4 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-blue-tic .elementor-icon-list-icon svg {
  color: #006df9;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-bf7f3ea */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for media-carousel, class: .elementor-element-e4828e2 */

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -10px !important;
  left: 0;
  width: 100%;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-a3830e4 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-0e631dd */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-d97a27b */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-da7326d */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-9041b17 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-d4600f0 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for html, class: .elementor-element-d15e8de */

.sliding-apps-icons {
  background: url(https://www.pabbly.com/wp-content/uploads/2022/11/Connect-1000-Applications-1.png);
  /* background-size: 100%; */
  animation: moves 25s infinite linear;
  height: 496px;
  margin: 0;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-0627ce3 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-3d43fee */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for ucaddon_pabbly_pricing_tabs, class: .elementor-element-34db2c1 */

.uc_pabbly_pricing_tabs_elementor section ul li a {
  border: 0 !important;
  text-align: left;
  padding: 0 !important;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-ab6f6fd */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-67ebbd2 */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-30c8f201 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-e29b2a4 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-bd75885 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-423ab80 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-48729cb */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-9f6b2f3 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-169f640 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-941435e */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-331328a8 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-6c2c7afb */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-3ba6f4c */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-3d4ff38 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-fb3c791 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-3f778343 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-16641576 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for toggle, class: .elementor-element-67fc4a5 */

.p-font-line-height p {
  font-size: 16px;
  line-height: 1.6;
}

/* End custom CSS */

/* Start custom CSS for icon-list, class: .elementor-element-cf668de */

li,
span {
  font-family: "Poppins", Sans-serif;
}

.close-brown-green-tic .elementor-icon-list-icon svg {
  color: #13c380;
}

/* End custom CSS */

/* Start Custom Fonts CSS */

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-Bold-1.ttf")
    format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2021/12/Inter-Black.ttf")
    format("truetype");
}

/* End Custom Fonts CSS */

/* Start Custom Fonts CSS */

@font-face {
  font-family: "unique-font";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2020/01/unique-font_1.woff")
    format("woff");
}

/* End Custom Fonts CSS */

/* Start Custom Fonts CSS */

@font-face {
  font-family: "Avenir Next W01";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2018/06/avenir100.woff2")
    format("woff2");
}

@font-face {
  font-family: "Avenir Next W01";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2018/06/avenir300.woff2")
    format("woff2");
}

@font-face {
  font-family: "Avenir Next W01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2018/06/avenir400.woff2")
    format("woff2");
}

@font-face {
  font-family: "Avenir Next W01";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2018/06/avenir600.woff2")
    format("woff2");
}

@font-face {
  font-family: "Avenir Next W01";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://www.pabbly.com/wp-content/uploads/2018/06/avenir700.woff2")
    format("woff2");
}

/* End Custom Fonts CSS */

.elementor-73682
  .elementor-element.elementor-element-18b3032:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-73682
  .elementor-element.elementor-element-18b3032
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #040b27;
}

.elementor-73682
  .elementor-element.elementor-element-18b3032
  > .elementor-background-overlay {
  background-image: url("https://www.pabbly.com/wp-content/uploads/2022/12/Popup-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-73682 .elementor-element.elementor-element-18b3032,
.elementor-73682
  .elementor-element.elementor-element-18b3032
  > .elementor-background-overlay {
  border-radius: 12px 12px 12px 12px;
}

.elementor-73682 .elementor-element.elementor-element-18b3032 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-20000c2
  > .elementor-element-populated,
.elementor-73682
  .elementor-element.elementor-element-20000c2
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-73682
  .elementor-element.elementor-element-20000c2
  > .elementor-background-slideshow {
  border-radius: 12px 12px 12px 12px;
}

.elementor-73682
  .elementor-element.elementor-element-20000c2
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 50px 50px 30px 50px;
}

.elementor-73682 .elementor-element.elementor-element-b1843bf {
  text-align: center;
  z-index: 999;
}

.elementor-73682
  .elementor-element.elementor-element-b1843bf
  .elementor-heading-title {
  color: #ffe800;
  font-family: "Inter", Sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0em;
}

.elementor-73682
  .elementor-element.elementor-element-b1843bf
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-4711b01
  > .elementor-widget-container {
  margin: -12px 0px 0px 6px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682 .elementor-element.elementor-element-ec37a25 {
  text-align: center;
}

.elementor-73682
  .elementor-element.elementor-element-ec37a25
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Inter", Sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.4em;
}

.elementor-73682
  .elementor-element.elementor-element-ec37a25
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682 .elementor-element.elementor-element-5d0589f {
  text-align: center;
}

.elementor-73682
  .elementor-element.elementor-element-5d0589f
  .elementor-heading-title {
  color: #ffffffb5;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-73682
  .elementor-element.elementor-element-5d0589f
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682 .elementor-element.elementor-element-65870e9 {
  text-align: center;
}

.elementor-73682
  .elementor-element.elementor-element-65870e9
  .elementor-heading-title {
  color: #ffe800;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
}

.elementor-73682
  .elementor-element.elementor-element-65870e9
  > .elementor-widget-container {
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-wrapper {
  max-width: 40%;
}

.elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-item {
  background-color: #ffffff;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #c9c9c9;
  border-radius: 10px 10px 10px 10px;
  padding: 16px 12px 3px 12px;
}

body:not(.rtl)
  .elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-item:not(:first-of-type) {
  margin-left: calc(8px / 2);
}

body:not(.rtl)
  .elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-item:not(:last-of-type) {
  margin-right: calc(8px / 2);
}

body.rtl
  .elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-item:not(:first-of-type) {
  margin-right: calc(8px / 2);
}

body.rtl
  .elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-item:not(:last-of-type) {
  margin-left: calc(8px / 2);
}

.elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-digits {
  color: #e71010;
  font-family: "Inter", Sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 0.5em;
}

.elementor-73682
  .elementor-element.elementor-element-0738513
  .elementor-countdown-label {
  color: #1e1e23;
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 2.2em;
}

.elementor-73682
  .elementor-element.elementor-element-0738513
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-1521880
  > .elementor-widget-container {
  margin: 0px 10px 0px 10px;
}

.elementor-73682
  .elementor-element.elementor-element-c89d447
  > .elementor-container {
  max-width: 500px;
}

.elementor-73682 .elementor-element.elementor-element-c89d447 {
  margin-top: -12px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-835ae37
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 30px;
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(0px / 2);
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(0px / 2);
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(0px / 2);
  margin-left: calc(0px / 2);
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-0px / 2);
  margin-left: calc(-0px / 2);
}

body.rtl
  .elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-0px / 2);
}

body:not(.rtl)
  .elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-0px / 2);
}

.elementor-73682 .elementor-element.elementor-element-111ab54 {
  --e-icon-list-icon-size: 12px;
  --e-icon-list-icon-align: center;
  --e-icon-list-icon-margin: 0 calc(var(--e-icon-list-icon-size, 1em) * 0.125);
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-text {
  color: #ffffff;
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-73682
  .elementor-element.elementor-element-111ab54
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.elementor-73682
  .elementor-element.elementor-element-111ab54
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-73682
  .elementor-element.elementor-element-12e1161
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 8px;
}

.elementor-73682 .elementor-element.elementor-element-1af2b68 {
  --e-icon-list-icon-size: 12px;
}

.elementor-73682
  .elementor-element.elementor-element-1af2b68
  .elementor-icon-list-text {
  color: #ffffff;
}

.elementor-73682
  .elementor-element.elementor-element-1af2b68
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-73682
  .elementor-element.elementor-element-1af2b68
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 12px;
  font-weight: 600;
}

#elementor-popup-modal-73682 .dialog-message {
  width: 640px;
  height: auto;
}

#elementor-popup-modal-73682 {
  justify-content: center;
  align-items: center;
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.8);
}

#elementor-popup-modal-73682 .dialog-close-button {
  display: flex;
}

#elementor-popup-modal-73682 .dialog-widget-content {
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-wrapper {
    max-width: 100%;
  }
  body:not(.rtl)
    .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-item:not(:first-of-type) {
    margin-left: calc(4px / 2);
  }
  body:not(.rtl)
    .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-item:not(:last-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-item:not(:first-of-type) {
    margin-right: calc(4px / 2);
  }
  body.rtl
    .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-item:not(:last-of-type) {
    margin-left: calc(4px / 2);
  }
  .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-digits {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-wrapper {
    max-width: 0%;
  }
  .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-digits {
    font-size: 14px;
  }
  .elementor-73682
    .elementor-element.elementor-element-0738513
    .elementor-countdown-label {
    font-size: 10px;
  }
  .elementor-73682
    .elementor-element.elementor-element-0738513
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 15px 0px 0px 0px;
  }
}

/* Start custom CSS for icon-list, class: .elementor-element-111ab54 */

ul.elementor-icon-list-items li.elementor-icon-list-item {
  list-style: none;
  margin: 0;
}

ul.elementor-icon-list-items
  li.elementor-icon-list-item
  .elementor-icon-list-icon {
  vertical-align: middle;
  margin-right: 5px;
}

/* End custom CSS */

/* Start custom CSS for section, class: .elementor-element-18b3032 */

#elementor-popup-modal-73682 .dialog-widget-content {
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  border-radius: 20px;
}

#elementor-popup-modal-73682 {
  justify-content: center;
  align-items: center;
  pointer-events: all;
  background-color: rgb(255 255 255 / 80%);
}

/* End custom CSS */

.elementor-20964
  .elementor-element.elementor-element-d1c25f4
  > .elementor-container {
  max-width: 1120px;
}

.elementor-20964
  .elementor-element.elementor-element-d1c25f4:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-20964
  .elementor-element.elementor-element-d1c25f4
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-20964 .elementor-element.elementor-element-d1c25f4 {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #f2f1f1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-d1c25f4
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-20964
  .elementor-element.elementor-element-a14f23b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-b62ef51
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-2a9bd20
  .elementor-heading-title {
  color: #1a2235;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-20964
  .elementor-element.elementor-element-2a9bd20
  > .elementor-widget-container {
  padding: 0px 0px 5px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-20964 .elementor-element.elementor-element-40e4a12 {
  --e-icon-list-icon-size: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-text {
  color: #575757;
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: #0ab06b;
}

.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-20964
  .elementor-element.elementor-element-40e4a12
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.elementor-20964
  .elementor-element.elementor-element-99cac8b
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-2f4923e
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-9df3a9e
  .elementor-heading-title {
  color: #1a2235;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-20964
  .elementor-element.elementor-element-9df3a9e
  > .elementor-widget-container {
  padding: 0px 0px 5px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-20964 .elementor-element.elementor-element-aba5c9c {
  --e-icon-list-icon-size: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-text {
  color: #575757;
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: #0ab06b;
}

.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-20964
  .elementor-element.elementor-element-aba5c9c
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.elementor-20964
  .elementor-element.elementor-element-a349d08
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-aac945b
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-ed8b9f2
  .elementor-heading-title {
  color: #1a2235;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-20964
  .elementor-element.elementor-element-ed8b9f2
  > .elementor-widget-container {
  padding: 0px 0px 5px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-20964 .elementor-element.elementor-element-31bbb8e {
  --e-icon-list-icon-size: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-text {
  color: #575757;
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: #0ab06b;
}

.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-20964
  .elementor-element.elementor-element-31bbb8e
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.elementor-20964
  .elementor-element.elementor-element-25399d1
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-0db653d
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-dadd322
  .elementor-heading-title {
  color: #1a2235;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-20964
  .elementor-element.elementor-element-dadd322
  > .elementor-widget-container {
  padding: 0px 0px 5px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:last-child) {
  padding-bottom: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items:not(.elementor-inline-items)
  .elementor-icon-list-item:not(:first-child) {
  margin-top: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item {
  margin-right: calc(8px / 2);
  margin-left: calc(8px / 2);
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items.elementor-inline-items {
  margin-right: calc(-8px / 2);
  margin-left: calc(-8px / 2);
}

body.rtl
  .elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  left: calc(-8px / 2);
}

body:not(.rtl)
  .elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-items.elementor-inline-items
  .elementor-icon-list-item:after {
  right: calc(-8px / 2);
}

.elementor-20964 .elementor-element.elementor-element-f524484 {
  --e-icon-list-icon-size: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-text {
  color: #575757;
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-item:hover
  .elementor-icon-list-text {
  color: #0ab06b;
}

.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-20964
  .elementor-element.elementor-element-f524484
  .elementor-icon-list-item
  > a {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.elementor-20964
  .elementor-element.elementor-element-bd489b8
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-b6fc7e1
  > .elementor-element-populated {
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-2974e73
  .elementor-heading-title {
  color: #1a2235;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.elementor-20964
  .elementor-element.elementor-element-2974e73
  > .elementor-widget-container {
  padding: 0px 0px 5px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-6249a38
  .elementor-heading-title {
  color: #575757;
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0.5px;
}

.elementor-20964
  .elementor-element.elementor-element-833accb
  .elementor-button {
  font-family: "Avenir Next W01", Sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 0em;
  background-color: #1877f2;
  border-radius: 5px 5px 5px 5px;
  padding: 0px 6px 0px 6px;
}

.elementor-20964
  .elementor-element.elementor-element-833accb
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 1px 0px 0px 0px;
}

.elementor-20964 .elementor-element.elementor-element-833accb {
  width: initial;
  max-width: initial;
}

.elementor-20964 .elementor-element.elementor-element-508610e {
  text-align: left;
  width: var(--container-widget-width, 39px);
  max-width: 39px;
  --container-widget-width: 39px;
  --container-widget-flex-grow: 0;
}

.elementor-20964 .elementor-element.elementor-element-508610e img {
  width: 100%;
  border-radius: 5px 5px 5px 5px;
}

.elementor-20964
  .elementor-element.elementor-element-508610e
  > .elementor-widget-container {
  padding: 0px 0px 0px 8px;
}

.elementor-20964 .elementor-element.elementor-element-f286ddf {
  text-align: left;
  width: var(--container-widget-width, 32px);
  max-width: 32px;
  --container-widget-width: 32px;
  --container-widget-flex-grow: 0;
}

.elementor-20964 .elementor-element.elementor-element-f286ddf img {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
}

.elementor-20964
  .elementor-element.elementor-element-f286ddf
  > .elementor-widget-container {
  padding: 0px 0px 0px 8px;
}

.elementor-20964 .elementor-element.elementor-element-5b021e5 {
  text-align: left;
  width: var(--container-widget-width, 32px);
  max-width: 32px;
  --container-widget-width: 32px;
  --container-widget-flex-grow: 0;
}

.elementor-20964 .elementor-element.elementor-element-5b021e5 img {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
}

.elementor-20964
  .elementor-element.elementor-element-5b021e5
  > .elementor-widget-container {
  padding: 0px 0px 0px 8px;
}

.elementor-20964
  .elementor-element.elementor-element-86705d5
  .elementor-icon-list-icon
  i {
  color: #222222;
}

.elementor-20964
  .elementor-element.elementor-element-86705d5
  .elementor-icon-list-icon
  svg {
  fill: #222222;
}

.elementor-20964 .elementor-element.elementor-element-86705d5 {
  --e-icon-list-icon-size: 15px;
}

.elementor-20964
  .elementor-element.elementor-element-86705d5
  .elementor-icon-list-text {
  color: #575757;
  padding-left: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-86705d5
  .elementor-icon-list-item
  > .elementor-icon-list-text,
.elementor-20964
  .elementor-element.elementor-element-86705d5
  .elementor-icon-list-item
  > a {
  font-family: "Inter", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 0.8em;
  letter-spacing: 0.4px;
}

.elementor-20964
  .elementor-element.elementor-element-86705d5
  > .elementor-widget-container {
  padding: 15px 0px 0px 0px;
}

.elementor-20964 .elementor-element.elementor-element-cc6ffef {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-e753f6a
  > .elementor-element-populated {
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 0px 0px 0px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-db1567e
  > .elementor-container {
  max-width: 1120px;
}

.elementor-20964
  .elementor-element.elementor-element-db1567e:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-20964
  .elementor-element.elementor-element-db1567e
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-20964 .elementor-element.elementor-element-db1567e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 11px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-db1567e
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-20964
  .elementor-element.elementor-element-6450b8b:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-20964
  .elementor-element.elementor-element-6450b8b
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}

.elementor-20964
  .elementor-element.elementor-element-6450b8b
  > .elementor-element-populated {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #e8e8e8;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 8px 0px 6px 0px;
}

.elementor-20964
  .elementor-element.elementor-element-6450b8b
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-20964 .elementor-element.elementor-element-82389c7 {
  text-align: center;
  width: auto;
  max-width: auto;
}

.elementor-20964
  .elementor-element.elementor-element-82389c7
  .elementor-heading-title {
  color: rgba(37, 44, 53, 0.9);
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-82389c7
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 8px 5px 8px 0px;
}

.elementor-20964 .elementor-element.elementor-element-194ff1f {
  text-align: center;
  width: auto;
  max-width: auto;
  align-self: center;
}

.elementor-20964
  .elementor-element.elementor-element-194ff1f
  .elementor-heading-title {
  color: rgba(37, 44, 53, 0.9);
  font-family: "Poppins", Sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
}

.elementor-20964
  .elementor-element.elementor-element-194ff1f
  > .elementor-widget-container {
  margin: 0px 0px 0px 0px;
  padding: 8px 0px 8px 5px;
  border-style: solid;
  border-width: 0px 0px 0px 1px;
  border-color: #e5e5e5;
}

@media (max-width: 1024px) {
  .elementor-20964 .elementor-element.elementor-element-d1c25f4 {
    padding: 40px 20px 0px 20px;
  }
  .elementor-20964
    .elementor-element.elementor-element-2a9bd20
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-20964
    .elementor-element.elementor-element-40e4a12
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-40e4a12
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-20964
    .elementor-element.elementor-element-9df3a9e
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-20964
    .elementor-element.elementor-element-aba5c9c
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-aba5c9c
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-20964
    .elementor-element.elementor-element-ed8b9f2
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-20964
    .elementor-element.elementor-element-31bbb8e
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-31bbb8e
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-20964
    .elementor-element.elementor-element-dadd322
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-20964
    .elementor-element.elementor-element-f524484
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-f524484
    .elementor-icon-list-item
    > a {
    font-size: 14px;
  }
  .elementor-20964
    .elementor-element.elementor-element-2974e73
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-20964
    .elementor-element.elementor-element-6249a38
    .elementor-heading-title {
    font-size: 14px;
    line-height: 1.5em;
  }
  .elementor-20964
    .elementor-element.elementor-element-6249a38
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-db1567e {
    padding: 0px 20px 12px 20px;
  }
}

@media (max-width: 767px) {
  .elementor-20964 .elementor-element.elementor-element-d1c25f4 {
    padding: 20px 20px 0px 20px;
  }
  .elementor-20964 .elementor-element.elementor-element-a14f23b {
    width: 50%;
  }
  .elementor-20964
    .elementor-element.elementor-element-a14f23b
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 10px 0px 0px;
  }
  .elementor-20964
    .elementor-element.elementor-element-40e4a12
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-40e4a12
    .elementor-icon-list-item
    > a {
    font-size: 13px;
  }
  .elementor-20964
    .elementor-element.elementor-element-40e4a12
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-99cac8b {
    width: 50%;
  }
  .elementor-20964
    .elementor-element.elementor-element-99cac8b
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 10px;
  }
  .elementor-20964
    .elementor-element.elementor-element-aba5c9c
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-aba5c9c
    .elementor-icon-list-item
    > a {
    font-size: 13px;
  }
  .elementor-20964
    .elementor-element.elementor-element-aba5c9c
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-a349d08 {
    width: 50%;
  }
  .elementor-20964
    .elementor-element.elementor-element-a349d08
    > .elementor-element-populated {
    padding: 0px 10px 0px 0px;
  }
  .elementor-20964
    .elementor-element.elementor-element-31bbb8e
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-31bbb8e
    .elementor-icon-list-item
    > a {
    font-size: 13px;
  }
  .elementor-20964
    .elementor-element.elementor-element-31bbb8e
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-25399d1 {
    width: 50%;
  }
  .elementor-20964
    .elementor-element.elementor-element-25399d1
    > .elementor-element-populated {
    padding: 0px 0px 0px 10px;
  }
  .elementor-20964
    .elementor-element.elementor-element-f524484
    .elementor-icon-list-item
    > .elementor-icon-list-text,
  .elementor-20964
    .elementor-element.elementor-element-f524484
    .elementor-icon-list-item
    > a {
    font-size: 13px;
  }
  .elementor-20964
    .elementor-element.elementor-element-f524484
    > .elementor-widget-container {
    margin: -10px 0px 0px 0px;
  }
  .elementor-20964
    .elementor-element.elementor-element-bd489b8
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 20px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-031f7c3 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-20964
    .elementor-element.elementor-element-6249a38
    .elementor-heading-title {
    font-size: 13px;
  }
  .elementor-20964 .elementor-element.elementor-element-82389c7 {
    text-align: center;
  }
  .elementor-20964
    .elementor-element.elementor-element-82389c7
    > .elementor-widget-container {
    padding: 10px 0px 0px 0px;
  }
  .elementor-20964 .elementor-element.elementor-element-194ff1f {
    text-align: center;
  }
  .elementor-20964
    .elementor-element.elementor-element-194ff1f
    > .elementor-widget-container {
    padding: 10px 0px 0px 10px;
  }
}

@media (min-width: 768px) {
  .elementor-20964 .elementor-element.elementor-element-a14f23b {
    width: 21%;
  }
  .elementor-20964 .elementor-element.elementor-element-99cac8b {
    width: 17%;
  }
  .elementor-20964 .elementor-element.elementor-element-a349d08 {
    width: 20%;
  }
  .elementor-20964 .elementor-element.elementor-element-25399d1 {
    width: 20%;
  }
  .elementor-20964 .elementor-element.elementor-element-bd489b8 {
    width: 22%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-20964 .elementor-element.elementor-element-a349d08 {
    width: 22%;
  }
  .elementor-20964 .elementor-element.elementor-element-25399d1 {
    width: 18%;
  }
}

.center {
  justify-content: center;
  align-items: center;
}
.column{
    flex-direction: column;
}

.row{
  flex-direction: row;
}